import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Button, Box, Alert, Tooltip, FormControlLabel } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { GridLocalization } from 'helpers/general';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DialogConfirmAction from 'components/DialogConfirmAction';
import Switch from '@mui/material/Switch';
import axios from 'helpers/CustomAxios';
import OutputFileForm from './OutputFileForm';
import CustomPagination from 'components/CustomPagination';
import { CSVLink } from "react-csv";
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { format } from 'date-fns'

function OutputFileAdministration({ onCancel, accountId,accountFiid, isView = false,
    isEdit = false, setShowButtons = null }) {
    const components = ["main", "create", "edit", "view"];
    const [openComponent, setOpenComponent] = useState(components[0]);
    const [data, setData] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [page, setPage] = useState(0);
    const [loadingData, setLoadingData] = useState(true);
    const [permissions, setPermissions] = useState({
        edit: true,
        view: true,
        create: true,
        changeActive: true
    })

    const [selectedId, setSelectedId] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [showConfirmChangeActive, setShowConfirmChangeActive] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filterActive, setFilterActive] = useState(true);

    const [csvData, setCsvData] = useState([])
    const [downloading, setDownloading] = useState(false)
    const csvLink = useRef(null);

    useEffect(() => {
        setSuccessMsg(null);
        setErrorMsg(null);
    }, [openComponent])


    useEffect(() => {
        loadData();
    }, [pageSize, page, filterActive])

    const columns = [
        { field: 'output_filebase', headerName: 'Nombre Base', flex: 2, sortable: false },
        { field: 'original_file', headerName: 'Original', flex: 2, sortable: false },
        {
            field: 'is_active', headerName: 'Activo', flex: 1, sortable: false,
            renderCell: (params) => {
                return (
                    <Box component="div" justifyContent="center" width="100%" >
                        {params.row.is_active ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon color="disabled" />}
                    </Box>
                )
            }
        },
        {
            field: 'actions', type: 'actions', headerName: "Acciones", flex: 2,
            getActions: (params) => loadActions(params)
        }
    ];

    const loadActions = (params) => {
        let actions = [];
        if (permissions.view) {
            actions.push(<GridActionsCellItem
                icon={
                    <Tooltip title="Ver detalle" placement="top" arrow>
                        <VisibilityIcon aria-label="Ver detalle" color="primary" />
                    </Tooltip>
                }
                label="Ver Detalle"
                onClick={() => {
                    setSelectedId(params.row.id)
                    if (setShowButtons) { setShowButtons(false) };
                    setOpenComponent(components[3])
                }}
            />)
        }
        if (permissions.edit && isEdit) {
            actions.push(
                <GridActionsCellItem
                    icon={
                        <Tooltip title="Editar" placement="top" arrow>
                            <EditIcon aria-label="Editar" color="primary" />
                        </Tooltip>}
                    label="Editar"
                    onClick={() => {
                        setSelectedId(params.row.id)
                        setOpenComponent(components[2])
                    }}
                    color="primary"
                />)
        }
        if (permissions.changeActive && params.row.is_active && isEdit) {
            actions.push(
                <GridActionsCellItem
                    icon={<Tooltip title="Desactivar" placement="top" arrow>
                        <CancelIcon aria-label="Desactivar" color="error" />
                    </Tooltip>}
                    label="Desactivar"
                    onClick={() => {
                        setSelectedRow(params.row)
                        setShowConfirmChangeActive(true);
                    }}
                    color="error"
                />)
        }
        if (permissions.changeActive && !params.row.is_active && isEdit) {
            actions.push(
                <GridActionsCellItem
                    icon={<Tooltip title="Activar" placement="top" arrow>
                        <CheckCircleIcon aria-label="Activar" color="info" />
                    </Tooltip>}
                    label="Activar"
                    onClick={() => {
                        setSelectedRow(params.row)
                        setShowConfirmChangeActive(true);
                    }}
                    color="info"
                />)
        }
        return (actions)
    }



    const loadData = () => {
        setLoadingData(true)
        axios.get("accountoutputfiles/", {
            params: {
                page: page + 1, size: pageSize, is_active: filterActive,
                account_id: accountId
            }
        })
            .then(function (response) {
                setData(response.data.items);
                setTotalRecords(response.data.total)
            })
            .catch(function (error) {
                console.log(error);
                setErrorMsg("Ha ocurrido un error");
            })
            .then(function () {
                setLoadingData(false)
            })
    }

    const backToList = (success = null) => {
        setOpenComponent(components[0])
        if (success) {
            setSuccessMsg(success)
        }
        if (setShowButtons) { setShowButtons(true) }
        loadData();
    }

    const handleChangeActive = () => {
        axios.post("utils/", null, { params: { table: "accountoutputfiles", id: selectedRow.id } })
            .then(function (response) {
                setSuccessMsg(selectedRow.original_file + " se ha editado correctamente.")
            })
            .catch(function (error) {
                console.log(error);
                setErrorMsg("Ha ocurrido un error");
            })
            .then(function () {
                loadData()
            })
    }

    useEffect(() => {
        if (csvData.length > 0 && csvLink) {
            csvLink.current.link.click()
            setDownloading(false)
            setCsvData([])
        }
    }, [csvData])

    const downloadCSV = () => {
        setDownloading(true)
        let params = {
            account_id: accountId,
            is_active: filterActive,
        }
        axios.get("accountoutputfiles/all/", {
            params: params
        }).then(function (response) {
            setCsvData(response.data)
        })
            .catch(function (error) {
                console.log(error);
                setErrorMsg("Ha ocurrido un error");
                setCsvData([])
            })
    }

    return (
        <>
            {openComponent === components[0] &&
                <>
                    <Grid container spacing={2} >
                        <DialogConfirmAction
                            open={showConfirmChangeActive}
                            header={(selectedRow?.is_active ? "Desactivar" : "Activar") + " " + selectedRow?.original_file}
                            message={"¿Está seguro que desea " + (selectedRow?.is_active ? "desactivar" : "activar") + " " + selectedRow?.original_file + "?"}
                            onClose={() => { setShowConfirmChangeActive(false); }}
                            onAccept={() => { setShowConfirmChangeActive(false); handleChangeActive(); }}
                            labelAccept={selectedRow?.is_active ? "Desactivar" : "Activar"}

                        />
                        <Grid item xs={12} container spacing={2} direction="row" justifyContent={{ xs: "center", sm: "space-between" }} alignItems="center">
                            <Grid item sm={8} xs={12}>
                                <Typography variant="h4">Configurar Output Files</Typography>
                            </Grid>
                            <Grid item container spacing={2} direction="row" sm={4} xs={12} justifyContent={{ xs: "center", sm: "flex-end" }}>
                                <Grid item>
                                    <LoadingButton loading={downloading} aria-label="Descargar CSV" variant="contained" size="large"
                                        onClick={() => { downloadCSV() }} disabled={totalRecords === 0} startIcon={<FileDownloadIcon />}>
                                        CSV
                                    </LoadingButton>
                                    <CSVLink
                                        data={csvData}
                                        separator={";"}
                                        filename={accountFiid + " Output Files " + format(new Date(), "dd-MM-yyyy") + ".csv"}
                                        className="hidden"
                                        ref={csvLink}
                                        target="_blank"
                                    />
                                </Grid>
                                {permissions.create && !isView &&
                                    <Grid item>
                                        <Button aria-label="Crear nuevo" variant="contained" size="large" startIcon={<AddCircleIcon />}
                                            onClick={() => { setOpenComponent(components[1]) }}>
                                            Nuevo
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                            {errorMsg &&
                                <Grid item xs={12}>
                                    <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                                </Grid>
                            }
                            {successMsg &&
                                <Grid item xs={12}>
                                    <Alert severity="success" onClose={() => { setSuccessMsg(null) }} sx={{ width: '100%' }}>{successMsg}</Alert>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} container spacing={2} direction="row" justifyContent={{ xs: "center", sm: "space-between" }} alignItems="center">
                            <Grid item md={3} sm={4} xs={12}>
                                <FormControlLabel
                                    control={<Switch
                                        checked={filterActive}
                                        onChange={(event) => { setFilterActive(event.target.checked) }}
                                        inputProps={{ 'aria-label': 'filtrar activas' }}
                                    />
                                    } label={filterActive ? "Mostrando Activos" : "Mostrando Inactivos"} />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <div style={{ height: 450, width: '100%' }}>
                                <div style={{ display: 'flex', height: '100%' }}>
                                    <div style={{ flexGrow: 1 }}>
                                        <DataGrid
                                            localeText={GridLocalization}
                                            disableColumnFilter
                                            density="compact"
                                            pagination
                                            paginationMode="server"
                                            components={{
                                                Pagination: CustomPagination,
                                            }}
                                            rowCount={totalRecords}
                                            loading={loadingData}
                                            pageSize={pageSize}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            page={page}
                                            onPageChange={(newPage) => setPage(newPage)}
                                            rows={data}
                                            columns={columns} />
                                    </div>
                                </div>
                            </div>
                        </Grid>

                    </Grid>
                    {!isView &&
                        <Grid container direction="row" spacing={2} justifyContent="space-between" sx={{ mt: 1 }}
                            alignItems="center"  >
                            <Grid item>
                                <Button variant="contained" aria-label="Volver" color="secondary" onClick={() => { onCancel() }}>
                                    Volver
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </>
            }
            {openComponent === components[1] &&
                <OutputFileForm onSuccess={backToList} accountId={accountId} onCancel={backToList} isCreate={true} />
            }
            {openComponent === components[2] &&
                <OutputFileForm onSuccess={backToList} accountId={accountId} onCancel={backToList} isEdit={true} selectedId={selectedId} />
            }
            {openComponent === components[3] &&
                <OutputFileForm onCancel={backToList} isView={true} selectedId={selectedId} accountId={accountId} />
            }
        </>

    )
}

export default OutputFileAdministration;