import React, {useState} from "react";
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { useField } from 'formik';
import { VisibilityOff, Visibility } from "@mui/icons-material";

function PasswordInputMUI({ isView, ...props }) {
    const [field, meta] = useField(props);
    const [showPassword, setShowPassword] = useState(false)

    return (
        <TextField
            fullWidth
            variant="outlined"
            {...field}
            {...props}
            type={showPassword ? "text" : "password"}
            error={meta.touched && Boolean(meta.error)}
            helperText={(meta.touched && meta.error) ? meta.error : (props.note ? props.note : null)}

            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="Cambiar visibilidad de contraseña"
                        onClick={()=>setShowPassword(true)}
                        onMouseDown={()=>setShowPassword(false)}
                        size="large"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )

}

export default PasswordInputMUI;