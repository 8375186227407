import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, Typography, Button, Slide, Grid, DialogTitle } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function DialogConfirmAction({ message, onClose, onAccept, open, header = null, labelCancel = "Cancelar", labelAccept = "Aceptar" }) {

    return (
        <Dialog open={open}
            transitioncomponent={Transition}
            keepMounted
            onClose={() => onClose()}
        >
            {header &&
                <DialogTitle>{header}</DialogTitle>
            }
            <DialogContent dividers>
                {Array.isArray(message) ?
                    message.map((line, index) => (
                        <Typography key={index} gutterBottom>
                            {line}
                        </Typography>
                    ))
                    :
                    <Typography gutterBottom>
                        {message}
                    </Typography>
                }
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="space-between" sx={{pl:2, pr:2, pb:1, pt:1}}>
                    <Grid item>
                        <Button onClick={() => onClose()} variant="contained" color="secondary">{labelCancel}</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => onAccept()} variant="contained" color="primary">{labelAccept}</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )

}

export default DialogConfirmAction;