import React from "react";
import TextField from '@mui/material/TextField';
import { useField } from 'formik';


function TextInputMUI({ isView, ...props }) {
    const [field, meta] = useField(props);

    return (
        <>
            {isView ?
                <TextField
                    fullWidth
                    variant="filled"
                    {...field}
                    {...props}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ readOnly: true }}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={(meta.touched && meta.error) ? meta.error : (props.note ? props.note : null)}
                />
                :
                <TextField
                    fullWidth
                    variant="outlined"
                    {...field}
                    {...props}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={(meta.touched && meta.error) ? meta.error : (props.note ? props.note : null)}
                />
            }
        </>
    )

}

export default TextInputMUI;