import React, { useState, useEffect } from 'react';
import {
    Box, Tab, Grid, Button
} from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AccountForm from './AccountForm';
import EmailForm from './EmailForm';
import FoldersForm from './FoldersForm';
import FileInputAdministration from './FileInputAdministration';
import OutputFileAdministration from './OutputFileAdministration';
import EmailGroupsAdministration from './EmailGroupsAdministration';

function MainForm({ onCancel, isView = true, selectedId = null }) {
    const tabs = ["Cuenta", "E-Mails", "Grupos de E-Mail", "Carpetas", "Input Files", "Output Files"]

    const [activeTab, setActiveTab] = useState('0');
    const [showButtons, setShowButtons] = useState(true);

    return (

        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={activeTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(event, newValue) => { setActiveTab(newValue) }}
                        aria-label="Tabs detalle de cuenta">
                        {tabs.map((tab, index) => (
                            <Tab label={tab} value={index.toString()} key={index} />
                        ))}
                    </TabList>
                </Box>
                <TabPanel value={'0'}>
                    <AccountForm isView={true} selectedId={selectedId} />
                </TabPanel>
                <TabPanel value={'1'}>
                    <EmailForm isView={true} selectedId={selectedId} />
                </TabPanel>
                <TabPanel value={'2'}>
                    <EmailGroupsAdministration isView={true} accountId={selectedId} setShowButtons={setShowButtons} />
                </TabPanel>
                <TabPanel value={'3'}>
                    <FoldersForm isView={true} selectedId={selectedId} />
                </TabPanel>
                <TabPanel value={'4'}>
                    <FileInputAdministration isView={true} accountId={selectedId} setShowButtons={setShowButtons} />
                </TabPanel>
                <TabPanel value={'5'}>
                    <OutputFileAdministration isView={true} accountId={selectedId} setShowButtons={setShowButtons} />
                </TabPanel>
            </TabContext>
            {showButtons &&
                <Grid container direction="row" spacing={2} justifyContent="space-between" sx={{ mt: 1 }}
                    alignItems="center"  >
                    <Grid item>
                        <Button variant="contained" aria-label="Volver" color="secondary" onClick={() => { onCancel() }}>
                            Volver
                        </Button>
                    </Grid>
                </Grid>
            }
        </Box>
    )
}

export default MainForm;