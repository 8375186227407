import React, { useState, useEffect } from 'react';
import { Grid, Button, Alert, Card, CardActions, CardContent, CardHeader, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInputMUI from 'components/TextInputMUI';
import axios from 'helpers/CustomAxios';

function ValidationGroupForm({ onCancel, onSuccess = null, selectedId, isView = false, isEdit = false, isCreate = false }) {
    const [duplicated, setDuplicated] = useState(false);
    const [specialName, setSetSpecialName] = useState(false);
    const [fiid, setFiid] = useState(false);
    const [totalPartial, setTotalPartial] = useState(false);
    const [sunday, setSunday] = useState(false);
    const [saturday, setSaturday] = useState(false);
    const [holiday, setHoliday] = useState(false);
    const [date, setDate] = useState(false);
    const [time, setTime] = useState(false);
    const [satSunday, setSatSunday] = useState(false);
    const [filemask, setFilemask] = useState(false);
    const [sequence, setSequence] = useState(false);
    const [nextWorkingDay, setNextWorkingDay] = useState(false);
    const [processWeekday, setProcessWeekday] = useState(false);
    const [processSaturday, setProcessSaturday] = useState(false);
    const [processSunday, setProcessSunday] = useState(false);
    const [active, setActive] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [initialData, setInitialData] = useState(null);

    useEffect(() => {
        if (selectedId) {
            axios.get("validationgroups/" + selectedId, {})
                .then(function (response) {
                    setInitialData(response.data)
                    setDuplicated(response.data.duplicated)
                    setSetSpecialName(response.data.special_name)
                    setFiid(response.data.fiid)
                    setTotalPartial(response.data.total_partial)
                    setSunday(response.data.sunday)
                    setSaturday(response.data.saturday)
                    setHoliday(response.data.holiday)
                    setDate(response.data.date)
                    setTime(response.data.time)
                    setSatSunday(response.data.sat_sunday)
                    setFilemask(response.data.filemask)
                    setSequence(response.data.sequence)
                    setNextWorkingDay(response.data.next_working_day)
                    setProcessWeekday(response.data.process_weekday)
                    setProcessSaturday(response.data.process_saturday)
                    setProcessSunday(response.data.process_sunday)
                    setActive(response.data.is_active)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                })
        }
    }, [])

    const getAction = () => {
        if (isCreate) {
            return "Crear"
        }
        else if (isEdit) {
            return "Editar"
        }
        else if (isView) {
            return "Ver"
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                name: initialData ? initialData.name : '',
                show_order: initialData ? initialData.show_order : 0,
                duplicated: initialData ? initialData.duplicated : false,
                special_name: initialData ? initialData.special_name : false,
                fiid: initialData ? initialData.fiid : false,
                total_partial: initialData ? initialData.total_partial : false,
                sunday: initialData ? initialData.sunday : false,
                saturday: initialData ? initialData.saturday : false,
                holiday: initialData ? initialData.holiday : false,
                date: initialData ? initialData.date : false,
                time: initialData ? initialData.time : false,
                sat_sunday: initialData ? initialData.sat_sunday : false,
                filemask: initialData ? initialData.filemask : false,
                sequence: initialData ? initialData.sequence : false,
                next_working_day: initialData ? initialData.next_working_day : false,
                process_weekday: initialData ? initialData.process_weekday : false,
                process_saturday: initialData ? initialData.process_saturday : false,
                process_sunday: initialData ? initialData.process_sunday : false,
                is_active: initialData ? initialData.is_active : false
            }}
            validationSchema={Yup.object({
                name: Yup.string().typeError("Nombre inválido").required("Campo requerido"),
                show_order: Yup.number().typeError("Ingrese un número entero").integer("Ingrese un número entero").min(0, "Ingrese un número igual o mayor a 0").required("Campo requerido"),

            })}
            onSubmit={(values, { setSubmitting }) => {
                
                values.id = selectedId ? selectedId : values.show_order
                if (isCreate) {
                    axios.post("validationgroups/", values)
                        .then(function (response) {
                            onSuccess("El grupo de validación " + values.name + " se ha creado correctamente.")
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                            setSubmitting(false);
                        })
                } else if (isEdit) {
                    axios.put("validationgroups/" + selectedId, values)
                        .then(function (response) {
                            onSuccess("El grupo de validación " + values.name + " se ha editado correctamente.")
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                            setSubmitting(false);
                        })
                }


            }}
        >
            {formik => (

                <form onSubmit={formik.handleSubmit}>

                    <Card>
                        <CardHeader title={getAction() + " Grupo de Validación"} />
                        <CardContent>
                            <Grid container spacing={2} alignContent="center">
                                {errorMsg &&
                                    <Grid item xs={12}>
                                        <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                                    </Grid>
                                }
                                <Grid item container md={12} direction="row" spacing={2}>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <TextInputMUI name="name" label={"Nombre"} required={true} isView={isView} />
                                    </Grid>
                                    <Grid item md={3} sm={3} xs={12}>
                                        <TextInputMUI type="number" name="show_order" label={"show_order"} required={true} isView={isView} />
                                    </Grid>
                                    <Grid item md={3} sm={3} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={active}
                                                onChange={(event) => {
                                                    formik.setFieldValue("is_active", event.target.checked)
                                                    setActive(event.target.checked)
                                                }} />} label="Activo" />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid item container md={12} direction="row" spacing={2}>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={duplicated}
                                                onChange={(event) => {
                                                    formik.setFieldValue("duplicated", event.target.checked)
                                                    setDuplicated(event.target.checked)
                                                }} />} label="duplicated" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={specialName}
                                                onChange={(event) => {
                                                    formik.setFieldValue("special_name", event.target.checked)
                                                    setSetSpecialName(event.target.checked)
                                                }} />} label="special_name" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={fiid}
                                                onChange={(event) => {
                                                    formik.setFieldValue("fiid", event.target.checked)
                                                    setFiid(event.target.checked)
                                                }} />} label="fiid" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={totalPartial}
                                                onChange={(event) => {
                                                    formik.setFieldValue("total_partial", event.target.checked)
                                                    setTotalPartial(event.target.checked)
                                                }} />} label="total_partial" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={sunday}
                                                onChange={(event) => {
                                                    formik.setFieldValue("sunday", event.target.checked)
                                                    setSunday(event.target.checked)
                                                }} />} label="sunday" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={saturday}
                                                onChange={(event) => {
                                                    formik.setFieldValue("saturday", event.target.checked)
                                                    setSaturday(event.target.checked)
                                                }} />} label="saturday" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={holiday}
                                                onChange={(event) => {
                                                    formik.setFieldValue("holiday", event.target.checked)
                                                    setHoliday(event.target.checked)
                                                }} />} label="holiday" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={date}
                                                onChange={(event) => {
                                                    formik.setFieldValue("date", event.target.checked)
                                                    setDate(event.target.checked)
                                                }} />} label="date" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={time}
                                                onChange={(event) => {
                                                    formik.setFieldValue("time", event.target.checked)
                                                    setTime(event.target.checked)
                                                }} />} label="time" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={satSunday}
                                                onChange={(event) => {
                                                    formik.setFieldValue("sat_sunday", event.target.checked)
                                                    setSatSunday(event.target.checked)
                                                }} />} label="sat_sunday" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={filemask}
                                                onChange={(event) => {
                                                    formik.setFieldValue("filemask", event.target.checked)
                                                    setFilemask(event.target.checked)
                                                }} />} label="filemask" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={sequence}
                                                onChange={(event) => {
                                                    formik.setFieldValue("sequence", event.target.checked)
                                                    setSequence(event.target.checked)
                                                }} />} label="sequence" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={nextWorkingDay}
                                                onChange={(event) => {
                                                    formik.setFieldValue("next_working_day", event.target.checked)
                                                    setNextWorkingDay(event.target.checked)
                                                }} />} label="next_working_day" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={processWeekday}
                                                onChange={(event) => {
                                                    formik.setFieldValue("process_weekday", event.target.checked)
                                                    setProcessWeekday(event.target.checked)
                                                }} />} label="process_weekday" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={processSaturday}
                                                onChange={(event) => {
                                                    formik.setFieldValue("process_saturday", event.target.checked)
                                                    setProcessSaturday(event.target.checked)
                                                }} />} label="process_saturday" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel disabled={isView} control={<Checkbox checked={processSunday}
                                                onChange={(event) => {
                                                    formik.setFieldValue("process_sunday", event.target.checked)
                                                    setProcessSunday(event.target.checked)
                                                }} />} label="process_sunday" />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions sx={{ ml: 1, pb: 2, pr: 2 }} >
                            <Grid container direction="row" spacing={2} justifyContent="space-between"
                                alignItems="center"  >

                                {isView ?
                                    <Grid item>
                                        <Button variant="contained" aria-label="Volver a listado" color="primary" onClick={() => { onCancel() }}>
                                            Volver
                                        </Button>
                                    </Grid>
                                    :
                                    <>
                                        <Grid item>
                                            <Button variant="contained" aria-label="Cancelar" color="secondary" onClick={() => { onCancel() }} disabled={formik.isSubmitting}>
                                                Cancelar
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" aria-label={getAction() + " grupo de validación"} type={"submit"} disabled={formik.isSubmitting}>
                                                {getAction()}
                                            </Button>
                                        </Grid>
                                    </>

                                }
                            </Grid>
                        </CardActions>
                    </Card>

                </form>
            )}
        </Formik>
    )
}

export default ValidationGroupForm;