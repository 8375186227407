import axios from "axios"

const getToken = () => {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString)
    return userToken
}

const API_URL = "$REACT_APP_URL_API"
const instance = axios.create({   
    //baseURL: process.env.REACT_APP_URL_API,
    baseURL: API_URL
})

instance.interceptors.request.use(function (config) {
    let token = getToken()
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 400 || error.response.status === 401) {
        localStorage.clear()
        window.dispatchEvent(new Event("storage"));       
    } 
        return Promise.reject(error);
    

});

export const loggedOutInstance = axios.create({ baseURL: API_URL })

export default instance;