import React, { useState, useEffect } from 'react';
import {
    Grid, Typography, Button, Box, Alert, Tooltip, Switch, FormControlLabel, Card, CardContent, CardActions,
} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { GridLocalization } from 'helpers/general';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DialogConfirmAction from 'components/DialogConfirmAction';

import axios from 'helpers/CustomAxios';
import AccountForm from './AccountForm';

import { Menu, MenuItem } from "@mui/material";
import EmailForm from './EmailForm';
import FoldersForm from './FoldersForm';
import MainForm from './MainForm';
import FileInputAdministration from './FileInputAdministration';
import OutputFileAdministration from './OutputFileAdministration';

import CustomPagination from 'components/CustomPagination';
import EmailGroupsAdministration from './EmailGroupsAdministration';

import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInputMUI from 'components/TextInputMUI';

const components = ["main", "create", "editAccount", "view", "editEmail", "editFolders", "editInputFiles", "editOutputFiles", "editEmailGroups"];

const FilterPanel = ({ setFilter, filter }) => {
    const [active, setActive] = useState(true);
    const [initialData, setInitialData] = useState(filter)
    return (
        <Grid container item spacing={2} sx={{ pt: 1, }}>
            <Grid item xs={12}>
                <Card >
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            fiid: initialData.fiid ? initialData.fiid : "",
                            is_active: true,
                        }}
                        validationSchema={Yup.object({
                            fiid: Yup.string().typeError("Campo inválido"),
                            is_active: Yup.boolean().typeError("Campo inválido"),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            setFilter(values)
                        }}
                    >
                        {formik => (
                            <form onSubmit={formik.handleSubmit}>

                                <CardContent>
                                    <Grid container spacing={2}  alignContent="center">
                                        <Grid item container xs={12} direction="row" spacing={2} alignContent="center">
                                            <Grid item md={3} sm={4} xs={12}>
                                                <FormControlLabel
                                                    control={<Switch
                                                        checked={active}
                                                        onChange={(event) => {
                                                            formik.setFieldValue("is_active", event.target.checked)
                                                            setActive(event.target.checked)
                                                        }}
                                                        inputProps={{ 'aria-label': 'filtrar activas' }}
                                                    />
                                                    } label={active ? "Mostrando Activos" : "Mostrando Inactivos"} />
                                            </Grid>
                                            <Grid item md={3} sm={4} xs={12}>
                                                <TextInputMUI name="fiid" label={"Fiid"} />
                                            </Grid>
                                            <Grid item md={6} sm={4} xs={12} container spacing={2}  alignItems="center"  >
                                                 <Grid item alignContent="center">
                                                 <Button variant="contained" aria-label={"aplicar-filtros"} type={"submit"} >
                                                    Aplicar Filtro
                                                </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions sx={{ ml: 1, pb: 2, pr: 2 }} >

                                </CardActions>


                            </form>
                        )}
                    </Formik>
                </Card>
            </Grid>
        </Grid>

    )
}

function AccountAdministration({setAppBarLabel}) {

    const [openComponent, setOpenComponent] = useState(components[0]);
    const [data, setData] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loadingData, setLoadingData] = useState(true);
    const [permissions, setPermissions] = useState({
        edit: true,
        view: true,
        create: true,
        changeActive: true
    })

    const [selectedId, setSelectedId] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [showConfirmChangeActive, setShowConfirmChangeActive] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);

    const [filter, setFilter] = useState({
        is_active: true,
        fiid: null
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClickMenu = (componentId) => {
        setAppBarLabel(selectedRow.account_fiid)
        setAnchorEl(null);
        setOpenComponent(components[componentId])
    }

    useEffect(() => {
        setSuccessMsg(null);
        setErrorMsg(null);
    }, [openComponent])


    useEffect(() => {
        loadData();
    }, [pageSize, page, filter])


    const columns = [
        { field: 'accountname', headerName: 'Nombre', flex: 2, sortable: false },
        { field: 'account_fiid', headerName: 'Fiid', flex: 2, sortable: false },
        {
            field: 'is_active', headerName: 'Activo', flex: 1, sortable: false,
            renderCell: (params) => {
                return (
                    <Box component="div" justifyContent="center" width="100%" >
                        {params.row.is_active ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon color="disabled" />}
                    </Box>
                )
            }
        },
        {
            field: 'actions', type: 'actions', headerName: "Acciones", flex: 2,
            getActions: (params) => loadActions(params)
        }
    ];

    const loadActions = (params) => {
        let actions = [];
        if (permissions.view) {
            actions.push(<GridActionsCellItem
                icon={
                    <Tooltip title="Ver detalle" placement="top" arrow>
                        <VisibilityIcon aria-label="Ver detalle" color="primary" />
                    </Tooltip>
                }
                label="Ver Detalle"
                onClick={() => {
                    setAppBarLabel(params.row.account_fiid)
                    setSelectedRow(params.row)
                    setSelectedId(params.row.id)
                    setOpenComponent(components[3])
                }}
            />)
        }
        if (permissions.edit) {
            actions.push(
                <GridActionsCellItem
                    icon={
                        <Tooltip title="Editar Cuenta" placement="top" arrow>
                            <EditIcon aria-label="Editar" color="primary" />
                        </Tooltip>
                    }
                    label="Editar"
                    onClick={(e) => {
                        setSelectedRow(params.row)
                        setSelectedId(params.row.id)
                        handleClick(e)
                    }}
                    color="primary" />)
        }
        if (permissions.changeActive && params.row.is_active) {
            actions.push(
                <GridActionsCellItem
                    icon={<Tooltip title="Desactivar" placement="top" arrow>
                        <CancelIcon aria-label="Desactivar" color="error" />
                    </Tooltip>}
                    label="Desactivar"
                    onClick={() => {
                        setSelectedRow(params.row)
                        setShowConfirmChangeActive(true);
                    }}
                    color="error"
                />)
        }
        if (permissions.changeActive && !params.row.is_active) {
            actions.push(
                <GridActionsCellItem
                    icon={<Tooltip title="Activar" placement="top" arrow>
                        <CheckCircleIcon aria-label="Activar" color="info" />
                    </Tooltip>}
                    label="Activar"
                    onClick={() => {
                        setSelectedRow(params.row)
                        setShowConfirmChangeActive(true);
                    }}
                    color="info"
                />)
        }
        return (actions)
    }


    const handleChangeActive = () => {
        axios.post("utils/", null, { params: { table: "accounts", id: selectedRow.id } })
            .then(function (response) {
                setSuccessMsg(selectedRow.accountname + " se ha editado correctamente.")
            })
            .catch(function (error) {
                console.log(error);
                setErrorMsg("Ha ocurrido un error");
            })
            .then(function () {
                loadData()
            })
    }



    const loadData = () => {
        setLoadingData(true)
        axios.get("accounts/", { params: { page: page + 1, size: pageSize, ...filter } })
            .then(function (response) {
                setData(response.data.items);
                setTotalRecords(response.data.total)
            })
            .catch(function (error) {
                console.log(error);
                setErrorMsg("Ha ocurrido un error");
            })
            .then(function () {
                setLoadingData(false)
            })
    }

    const backToList = (success = null) => {
        setAppBarLabel(null)
        setOpenComponent(components[0])
        if (success) {
            setSuccessMsg(success)
        }
        loadData();
    }
    const goToView = (success = null, selId) => {
        setSelectedId(selId)
        setOpenComponent(components[3])
        if (success) {
            setSuccessMsg(success)
        }
        loadData();
    }

    return (
        <>
            {openComponent === components[0] &&
                <Grid container spacing={2}>
                    <Menu
                        id="menu-editar"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'boton menu editar',
                        }}
                    >
                        <MenuItem onClick={() => { handleClickMenu(2) }}>Cuenta</MenuItem>
                        <MenuItem onClick={() => { handleClickMenu(4) }}>E-Mail</MenuItem>
                        <MenuItem onClick={() => { handleClickMenu(8) }}>Grupos de E-Mail</MenuItem>
                        <MenuItem onClick={() => { handleClickMenu(5) }}>Carpeta</MenuItem>
                        <MenuItem onClick={() => { handleClickMenu(6) }}>Input Files</MenuItem>
                        <MenuItem onClick={() => { handleClickMenu(7) }}>Output Files</MenuItem>

                    </Menu>
                    <DialogConfirmAction
                        open={showConfirmChangeActive}
                        header={(selectedRow?.is_active ? "Desactivar" : "Activar") + " " + selectedRow?.accountname}
                        message={"¿Está seguro que desea " + (selectedRow?.is_active ? "desactivar" : "activar") + " " + selectedRow?.accountname + "?"}
                        onClose={() => { setShowConfirmChangeActive(false); }}
                        onAccept={() => { setShowConfirmChangeActive(false); handleChangeActive(); }}
                        labelAccept={selectedRow?.is_active ? "Desactivar" : "Activar"}

                    />
                    <Grid item xs={12} container spacing={2} direction="row" justifyContent={{ xs: "center", sm: "space-between" }} alignItems="center">
                        <Grid item md={9} sm={8} xs={12}>
                            <Typography variant="h4">Administrar Cuentas</Typography>
                        </Grid>
                        {permissions.create &&
                            <Grid item md={3} sm={4} xs={12}>
                                <Box display="flex" justifyContent={{ xs: "center", sm: "flex-end" }}>
                                    <Button aria-label="Crear nuevo" variant="contained" size="large" startIcon={<AddCircleIcon />}
                                        onClick={() => { setOpenComponent(components[1]) }}>
                                        Nueva
                                    </Button>
                                </Box>
                            </Grid>
                        }
                        {errorMsg &&
                            <Grid item xs={12}>
                                <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                            </Grid>
                        }
                        {successMsg &&
                            <Grid item xs={12}>
                                <Alert severity="success" onClose={() => { setSuccessMsg(null) }} sx={{ width: '100%' }}>{successMsg}</Alert>
                            </Grid>
                        }
                    </Grid>

                    <FilterPanel setFilter={setFilter} filter={filter} />
                    <Grid item xs={12}>
                        <div style={{ height: 450, width: '100%' }}>
                            <div style={{ display: 'flex', height: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                    <DataGrid
                                        localeText={GridLocalization}
                                        disableColumnFilter
                                        density="compact"
                                        pagination
                                        paginationMode="server"
                                        components={{
                                            Pagination: CustomPagination,
                                        }}
                                        loading={loadingData}
                                        pageSize={pageSize}
                                        rowCount={totalRecords}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        page={page}
                                        onPageChange={(newPage) => setPage(newPage)}
                                        rows={data}
                                        columns={columns} />
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            }
            {openComponent === components[1] &&
                <AccountForm onSuccess={goToView} onCancel={backToList} isCreate={true} />
            }
            {openComponent === components[2] &&
                <AccountForm onSuccess={backToList} onCancel={backToList} isEdit={true} selectedId={selectedId} />
            }
            {openComponent === components[3] &&
                <MainForm onCancel={backToList} isView={true} selectedId={selectedId} />
            }
            {openComponent === components[4] &&
                <EmailForm onSuccess={backToList} onCancel={backToList} isEdit={true} selectedId={selectedId} />
            }
            {openComponent === components[5] &&
                <FoldersForm onSuccess={backToList} onCancel={backToList} isEdit={true} selectedId={selectedId} />
            }
            {openComponent === components[6] &&
                <FileInputAdministration onSuccess={backToList} onCancel={backToList} isEdit={true} accountId={selectedRow.id} accountFiid={selectedRow.account_fiid}/>
            }
            {openComponent === components[7] &&
                <OutputFileAdministration onSuccess={backToList} onCancel={backToList} isEdit={true} accountId={selectedRow.id} accountFiid={selectedRow.account_fiid} />
            }
            {openComponent === components[8] &&
                <EmailGroupsAdministration onSuccess={backToList} onCancel={backToList} isEdit={true} accountId={selectedId} />
            }
        </>

    )
}

export default AccountAdministration;