import React, { forwardRef } from 'react';
import EventIcon from '@mui/icons-material/Event';
import { Button } from "@mui/material";

const MUIDatePicker = forwardRef(({ value, disabled, onClick, fixed }, ref) => {
    return (
        <Button variant="contained" color="primary" disabled={disabled} onClick={onClick} ref={ref}
            endIcon={<EventIcon />}>{fixed ? value.slice(0, -5) : value}
        </Button>
    )
})

export default MUIDatePicker;