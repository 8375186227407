import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiListItem: {
            defaultProps: {
                disableTouchRipple: true,

            },
        },
        MuiTablePagination: {
            defaultProps: {
                labelRowsPerPage: 'Filas por página:',
                labelDisplayedRows: ({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`,
            }
        },
        MuiPagination:{
            styleOverrides:{
                ul: {
                    justifyContent:"center"
                }
            }
        }
    },
    palette: {
        primary: {
            main: "#11327a",
        },
        secondary: {
            main: '#64748B',
            contrastText: '#fff',
        },
        warning: {
            main: "#fed802",
        },
        info: {
            main: "#29b4b6",
        },
        success: {
            main: "#29b4b6",
        },
        error: {
            main: "#ff1957"
        }
    },

});


export default theme;