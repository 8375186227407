import React, { useState } from 'react';
import { Grid, Button, Alert, CardContent, Card, CardActions, Typography } from "@mui/material";
import DialogConfirmAction from 'components/DialogConfirmAction';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'helpers/CustomAxios';
import PasswordInputMUI from "components/PasswordInputMUI";
import {parseJwt} from "helpers/general"
import useToken from 'helpers/useToken';
function ChangePassword() {

    const [permissions, setPermissions] = useState({
        edit: true,
        view: true,
        create: true,
        changeActive: true
    })
    const { token, setToken } = useToken();
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false)

    return (
        <Grid container spacing={2} alignContent="center" alignItems="center"
            justifyContent="center">

            <Grid item lg={4} md={4} sm={6} xs={10}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        old_password: '',
                        new_password: '',
                        confirmPassword: '',
                    }}
                    validationSchema={Yup.object({
                        old_password: Yup.string().typeError("Contraseña inválido").required("Campo requerido"),
                        new_password: Yup.string().typeError("Contraseña inválida").required("Campo requerido"),
                        confirmPassword: Yup.string().typeError("Contraseña inválida")
                            .oneOf([Yup.ref('new_password')], "Las contraseñas no coinciden").required("Campo requerido"),

                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        let requestData = {
                            old_password: values.old_password,
                            new_password: values.new_password,
                            username: parseJwt(token).username
                        }
                        axios.put("auth/", null, { params: {...requestData}})
                        .then(function (response) {
                            setSuccessMsg("La contraseña se ha cambiado correctamente.")
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");

                        })
                        .then(() => {
                            setSubmitting(false);
                        })
                    }}
                >
                    {formik => (

                        <form onSubmit={formik.handleSubmit}>
                            <Card>

                                <CardContent>

                                    <Grid container spacing={2} >
                                        <Grid container item xs={12} alignContent="center" alignItems="center"
                                            justifyContent="center">
                                            <Typography variant="h5">Cambiar Contraseña</Typography>
                                        </Grid>
                                        {errorMsg &&
                                            <Grid item xs={12}>
                                                <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                                            </Grid>
                                        }
                                        {successMsg &&
                                            <Grid item xs={12}>
                                                <Alert severity="success" onClose={() => { setSuccessMsg(null) }} sx={{ width: '100%' }}>{successMsg}</Alert>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <PasswordInputMUI name="old_password" label={"Contraseña Actual"} required={true} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PasswordInputMUI name="new_password" label={"Contraseña Nueva"} required={true} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PasswordInputMUI name="confirmPassword" label={"Reingrese la Contraseña Nueva"} required={true} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions sx={{ ml: 1, pb: 2, pr: 2 }} >
                                    <Grid container direction="row" spacing={2} justifyContent="center"
                                        alignItems="center"  >
                                        <Grid item>
                                            <Button variant="contained" type="button" onClick={() => { setShowConfirm(true) }} aria-label="Ingresar" color="primary">
                                                Cambiar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <DialogConfirmAction
                                        open={showConfirm}
                                        header={"Cambio de Contraseña"}
                                        message={"¿Está seguro que desea cambiar la contraseña?"}
                                        onClose={() => { setShowConfirm(false); }}
                                        onAccept={() => { setShowConfirm(false); formik.submitForm(); }}
                                        labelAccept={"Aceptar"}

                                    />
                                </CardActions>
                            </Card>
                        </form>
                    )}
                </Formik>
            </Grid>
        </Grid>



    )
}

export default ChangePassword;