import React, { useState, useEffect } from 'react';
import { Grid, Button, Alert, Card, CardActions, CardContent, CardHeader } from "@mui/material";
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInputMUI from 'components/TextInputMUI';
import axios from 'helpers/CustomAxios';

function SpecialNameForm({ onCancel, onSuccess = null, selectedId, isView = false, isEdit = false, isCreate = false }) {
    const [errorMsg, setErrorMsg] = useState(null);
    const [initialData, setInitialData] = useState(null);

    useEffect(() => {
        if (selectedId) {
            axios.get("specialname/" + selectedId, {})
                .then(function (response) {
                    console.log(response.data)
                    setInitialData(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                })
        }
    }, [])

    const getAction = () => {
        if (isCreate) {
            return "Crear"
        }
        else if (isEdit) {
            return "Editar"
        }
        else if (isView) {
            return "Ver"
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                fiid: initialData ? initialData.fiid : '',
                body_from: initialData ? initialData.body_from : 0,
                body_len: initialData ? initialData.body_len : 0,
                date_from: initialData ? initialData.date_from : 0,
                date_len: initialData ? initialData.date_len : 0,
                date_format: initialData ? initialData.date_format : '',
                sequence_from: initialData ? initialData.sequence_from : 0,
                sequence_len: initialData ? initialData.sequence_len : 0,
                text_from: initialData ? initialData.text_from : 0,
                text_len: initialData ? initialData.text_len : 0,
            }}
            validationSchema={Yup.object({
                fiid: Yup.string().typeError("Fiid inválido").required("Campo requerido"),
                date_format: Yup.string().typeError("Formato de fecha inválido").required("Campo requerido"),
                body_from: Yup.number().typeError("Ingrese un número entero").integer("Ingrese un número entero").min(0,"Ingrese un número igual o mayor a 0").required("Campo requerido"),
                body_len: Yup.number().typeError("Ingrese un número entero").integer("Ingrese un número entero").min(0,"Ingrese un número igual o mayor a 0").required("Campo requerido"),
                date_from: Yup.number().typeError("Ingrese un número entero").integer("Ingrese un número entero").min(0,"Ingrese un número igual o mayor a 0").required("Campo requerido"),
                date_len: Yup.number().typeError("Ingrese un número entero").integer("Ingrese un número entero").min(0,"Ingrese un número igual o mayor a 0").required("Campo requerido"),
                sequence_from: Yup.number().typeError("Ingrese un número entero").integer("Ingrese un número entero").min(0,"Ingrese un número igual o mayor a 0").required("Campo requerido"),
                sequence_len: Yup.number().typeError("Ingrese un número entero").integer("Ingrese un número entero").min(0,"Ingrese un número igual o mayor a 0").required("Campo requerido"),
                text_from: Yup.number().typeError("Ingrese un número entero").integer("Ingrese un número entero").min(0,"Ingrese un número igual o mayor a 0").required("Campo requerido"),
                text_len: Yup.number().typeError("Ingrese un número entero").integer("Ingrese un número entero").min(0,"Ingrese un número igual o mayor a 0").required("Campo requerido"),

            })}
            onSubmit={(values, { setSubmitting }) => {
                values.id = selectedId ? selectedId : 3
                if (isCreate) {
                   
                    axios.post("specialname/", values)
                        .then(function (response) {
                            onSuccess("El nombre especial se ha creado correctamente.")
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                            setSubmitting(false);
                        })
                } else if (isEdit) {
                    axios.put("specialname/" + selectedId, values)
                        .then(function (response) {
                            onSuccess("El nombre especial se ha editado correctamente.")
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                            setSubmitting(false);
                        })
                }


            }}
        >
            {formik => (

                <form onSubmit={formik.handleSubmit}>

                    <Card>
                        <CardHeader title={getAction() + " Nombre Especial"} />
                        <CardContent>
                            <Grid container spacing={2} alignContent="center">
                                {errorMsg &&
                                    <Grid item xs={12}>
                                        <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                                    </Grid>
                                }
                                <Grid item container md={12} direction="row" spacing={2}>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <TextInputMUI name="fiid" label={"Fiid"} required={true} isView={isView} />
                                    </Grid>
                                </Grid>
                                <Grid item container md={4} direction="row" spacing={2}>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <TextInputMUI type="number" name="body_from" label={"body_from"} required={true} isView={isView} />
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <TextInputMUI type="number" name="body_len" label={"body_len"} required={true} isView={isView} />
                                    </Grid>
                                </Grid>

                                <Grid item container md={4} direction="row" spacing={2}>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <TextInputMUI type="number" name="sequence_from" label={"sequence_from"} required={true} isView={isView} />
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <TextInputMUI type="number" name="sequence_len" label={"sequence_len"} required={true} isView={isView} />
                                    </Grid>
                                </Grid>
                                <Grid item container md={4} direction="row" spacing={2}>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <TextInputMUI type="number" name="text_from" label={"text_from"} required={true} isView={isView} />
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <TextInputMUI type="number" name="text_len" label={"text_len"} required={true} isView={isView} />
                                    </Grid>
                                </Grid>

                                <Grid item container md={12} direction="row" spacing={2}>
                                    <Grid item md={4} sm={4} xs={12}>
                                        <TextInputMUI type="number" name="date_from" label={"date_from"} required={true} isView={isView} />
                                    </Grid>
                                    <Grid item md={4} sm={4} xs={12}>
                                        <TextInputMUI type="number" name="date_len" label={"date_len"} required={true} isView={isView} />
                                    </Grid>
                                    <Grid item md={4} sm={4} xs={12}>
                                        <TextInputMUI name="date_format" label={"date_format"} required={true} isView={isView} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions sx={{ ml: 1, pb: 2, pr: 2 }} >
                            <Grid container direction="row" spacing={2} justifyContent="space-between"
                                alignItems="center"  >

                                {isView ?
                                    <Grid item>
                                        <Button variant="contained" aria-label="Volver a listado" color="primary" onClick={() => { onCancel() }}>
                                            Volver
                                        </Button>
                                    </Grid>
                                    :
                                    <>
                                        <Grid item>
                                            <Button variant="contained" aria-label="Cancelar" color="secondary" onClick={() => { onCancel() }} disabled={formik.isSubmitting}>
                                                Cancelar
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" aria-label={getAction() + " nombre especial"} type={"submit"} disabled={formik.isSubmitting}>
                                                {getAction()}
                                            </Button>
                                        </Grid>
                                    </>

                                }
                            </Grid>
                        </CardActions>
                    </Card>

                </form>
            )}
        </Formik>
    )
}

export default SpecialNameForm;