import React, { useState, useEffect } from 'react';
import {
    Grid, Button, Alert, Card, CardActions, CardContent, Autocomplete, TextField,
    FormGroup, FormControlLabel, Checkbox, Typography, IconButton,
    CircularProgress, Box
} from "@mui/material";
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import TextInputMUI from 'components/TextInputMUI';
import axios from 'helpers/CustomAxios';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BoxDivider from 'components/BoxDivider';

function FoldersForm({ onCancel, onSuccess = null, selectedId, isView = false,
    isEdit = false, isCreate = false }) {
    const [activeStates, setActiveStates] = useState([false]);
    const [readStates, setReadStates] = useState([false]);
    const [errorMsg, setErrorMsg] = useState(null);
    const [initialData, setInitialData] = useState(null);
    const [serverFolderOptions, setServerFolderOptions] = useState([]);
    const [fullyLoaded, setFullyLoaded] = useState(false)


    const [selectedServerFolders, setSelectedServerFolders] = useState(['']);
    const [activeBaseFolders, setActiveBaseFolders] = useState([false]);

    useEffect(() => {
        let serverFolderFormatted = null
        axios.get("serversfolders/combo")
            .then(function (response) {
                serverFolderFormatted = response.data
                setServerFolderOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
                setErrorMsg("Ha ocurrido un error");
            })
            .then(() => {
                if (selectedId) {
                    axios.get("accounts/" + selectedId, {})
                        .then(function (response) {
                            if (response.data.base_folder) {
                                let serverFolders = []
                                let activeBFolders = []

                                let foldersIsActive = []
                                let foldersReadingFolder = []

                                response.data.base_folder.forEach((baseFolder, index) => {
                                    serverFolders.push(serverFolderFormatted.find(o => o.value === baseFolder.server_folder_id));
                                    activeBFolders.push(baseFolder.is_active)

                                    let reading = []
                                    let active = []
                                    baseFolder.account_folders.forEach(folder => {
                                        reading.push(folder.reading_folder);
                                        active.push(folder.is_active)
                                    });
                                    foldersReadingFolder.push(reading);
                                    foldersIsActive.push(active)
                                });
                                setSelectedServerFolders(serverFolders)
                                setActiveBaseFolders(activeBFolders)
                                setReadStates(foldersReadingFolder);
                                setActiveStates(foldersIsActive);

                            }
                            setInitialData(response.data)
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                        }).then(() => {
                            setFullyLoaded(true)
                        })
                } else {
                    setFullyLoaded(true)
                }
            })

    }, [])

    const getAction = () => {
        if (isCreate) {
            return "Crear"
        }
        else if (isEdit) {
            return "Editar"
        }
        else if (isView) {
            return "Ver"
        }
    }


    const emptyFolder =
    {
        work_folder: '',
        reading_folder: false,
        is_active: false
    }

    const emptyBaseFolder = {
        base_folder: '',
        is_active: false,
        server_folder_id: null,
        account_folders: []
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                accountId: selectedId,
                base_folder: initialData?.base_folder ? initialData.base_folder :
                    [{
                        base_folder: '',
                        is_active: false,
                        server_folder_id: null,
                        account_folders: initialData?.base_folder ? initialData.base_folder.account_folders :
                            [{ ...emptyFolder }]
                    }]
            }}
            validationSchema={
                Yup.object({
                    base_folder:
                        Yup.array().of(
                            Yup.object().shape(
                                {
                                    base_folder: Yup.string().typeError("Campo inválido").required("Campo requerido"),
                                    is_active: Yup.boolean().typeError("Campo inválido"),
                                    account_folders: Yup.array()
                                        .of(
                                            Yup.object().shape({
                                                work_folder: Yup.string().typeError("Campo inválido").required("Campo requerido"),
                                                reading_folder: Yup.boolean().typeError("Campo inválido"),
                                                is_active: Yup.boolean().typeError("Campo inválido"),
                                            })
                                        )
                                })
                        ),

                })
            }
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                let requestData = { ...initialData }
                requestData.base_folder = values.base_folder;
                if (isEdit) {
                    axios.put("accounts/" + selectedId, requestData)
                        .then(function (response) {
                            onSuccess(initialData.accountname + " se ha editado correctamente.")
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                            setSubmitting(false);
                        })
                }


            }}
        >
            {formik => (

                <form onSubmit={formik.handleSubmit}>

                    <Card >
                        <CardContent>
                            {!fullyLoaded ?
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, height: "400px", alignItems: "center", }}>
                                    <CircularProgress />
                                </Box> :
                                <Grid container spacing={2} alignContent="center">
                                    {errorMsg &&
                                        <Grid item xs={12}>
                                            <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                                        </Grid>
                                    }
                                    <Grid item container md={12} direction="row" spacing={2}>
                                        <FieldArray name="base_folder"
                                            render={arrayHelpers => (
                                                <React.Fragment>
                                                    <Grid item container md={12} direction="row" spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6">Carpetas Base</Typography>
                                                        </Grid>
                                                        {formik.values.base_folder && formik.values.base_folder.length > 0 ?
                                                            (
                                                                formik.values.base_folder.map((baseFolder, index) =>
                                                                    <Grid key={index} item xs={12}>
                                                                        <Card variant="outlined">
                                                                            <CardContent>
                                                                                <Grid item container xs={12} spacing={2}>
                                                                                    <Grid item container alignContent="center" xs={12} direction="row" spacing={2}>
                                                                                        <Grid item sm={4} xs={12}>
                                                                                            <Autocomplete
                                                                                                disablePortal
                                                                                                value={selectedServerFolders[index]}
                                                                                                onChange={(event, newValue) => {
                                                                                                    let newSelectedServer = [...selectedServerFolders]
                                                                                                    newSelectedServer[index] = newValue
                                                                                                    setSelectedServerFolders(newSelectedServer)
                                                                                                    formik.setFieldValue(`base_folder.${index}.server_folder_id`, newValue?.value)
                                                                                                }}
                                                                                                id="combo-server-folder"
                                                                                                disabled={isView}
                                                                                                options={serverFolderOptions}
                                                                                                renderInput={(params) =>
                                                                                                    <TextField {...params} disabled={isView} required
                                                                                                        label="Carpeta de Servidor" variant={isView ? "filled" : "outlined"} />}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item sm={4} xs={12}>
                                                                                            <TextInputMUI name={`base_folder.${index}.base_folder`} label={"Ruta"} required={true} isView={isView} />
                                                                                        </Grid>
                                                                                        <Grid container item sm={2} xs={6} alignContent="center">
                                                                                            <FormGroup >
                                                                                                <FormControlLabel disabled={isView}
                                                                                                    control={<Checkbox checked={activeBaseFolders[index]}
                                                                                                        onChange={(event) => {
                                                                                                            formik.setFieldValue(`base_folder.${index}.is_active`, event.target.checked)
                                                                                                            let newActiveFolders = [...activeBaseFolders]
                                                                                                            newActiveFolders[index] = event.target.checked
                                                                                                            setActiveBaseFolders(newActiveFolders)
                                                                                                        }} />} label="Activo" />
                                                                                            </FormGroup>
                                                                                        </Grid>

                                                                                        <Grid container item sm={2} xs={6} justifyContent="flex-end">
                                                                                            <IconButton aria-label="eliminar carpeta base"
                                                                                                onClick={() => {
                                                                                                    arrayHelpers.remove(index)
                                                                                                    const newActiveFolders = [...activeBaseFolders]
                                                                                                    newActiveFolders.splice(index, 1)
                                                                                                    const newSelectedServer = [...selectedServerFolders]
                                                                                                    newSelectedServer.splice(index, 1)
                                                                                                    setActiveBaseFolders(newActiveFolders)
                                                                                                    setSelectedServerFolders(newSelectedServer)

                                                                                                    const newActiveStates = [...activeStates]
                                                                                                    newActiveStates.splice(index, 1)
                                                                                                    const newReadStates = [...readStates]
                                                                                                    newReadStates.splice(index, 1)
                                                                                                    setActiveStates(newActiveStates)
                                                                                                    setReadStates(newReadStates)
                                                                                                }}
                                                                                                color="error">
                                                                                                <DeleteIcon />
                                                                                            </IconButton>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid item container alignContent="center" xs={12} direction="row" spacing={2} justifyContent="center">
                                                                                        <FieldArray name={`base_folder.${index}.account_folders`}
                                                                                            render={arrayHelpers => (
                                                                                                <React.Fragment>
                                                                                                    <Grid container item spacing={2} direction="row" alignItems="center" sm={12} sx={{ pt: 2, pb: 2 }}>
                                                                                                        <Grid item>
                                                                                                            <Typography variant="h6">Carpetas Internas</Typography>
                                                                                                        </Grid>

                                                                                                    </Grid>
                                                                                                    <Grid container item md={12} sm={12} xs={12} spacing={2} sx={{ pb: 2 }}>

                                                                                                        {formik.values.base_folder[index].account_folders && formik.values.base_folder[index].account_folders.length > 0 ?
                                                                                                            (
                                                                                                                formik.values.base_folder[index].account_folders.map((folder, folderIndex) =>
                                                                                                                    <Grid key={folderIndex} item container alignContent="center" xs={12} direction="row" spacing={2}>
                                                                                                                        <Grid item md={5} sm={5} xs={12}>
                                                                                                                            <TextInputMUI name={`base_folder.${index}.account_folders.${folderIndex}.work_folder`} label={"Ruta"} required={true}
                                                                                                                                isView={isView || folder.work_folder === "upload" || folder.work_folder === "download"} />
                                                                                                                        </Grid>
                                                                                                                        <Grid item md={3} sm={3} xs={4}>
                                                                                                                            <FormGroup>
                                                                                                                                <FormControlLabel disabled={isView || folder.work_folder === "upload" || folder.work_folder === "download"}
                                                                                                                                    control={<Checkbox checked={readStates[index][folderIndex]}
                                                                                                                                        onChange={(event) => {
                                                                                                                                            formik.setFieldValue(`base_folder.${index}.account_folders.${folderIndex}.reading_folder`, event.target.checked)
                                                                                                                                            let newReadStates = [...readStates];
                                                                                                                                            newReadStates[index][folderIndex] = event.target.checked;
                                                                                                                                            setReadStates(newReadStates)
                                                                                                                                        }} />} label="Lectura" />
                                                                                                                            </FormGroup>
                                                                                                                        </Grid>
                                                                                                                        <Grid item md={3} sm={3} xs={4}>
                                                                                                                            <FormGroup>
                                                                                                                                <FormControlLabel disabled={isView || folder.work_folder === "upload" || folder.work_folder === "download"}
                                                                                                                                    control={<Checkbox checked={activeStates[index][folderIndex]}
                                                                                                                                        onChange={(event) => {
                                                                                                                                            formik.setFieldValue(`base_folder.${index}.account_folders.${folderIndex}.is_active`, event.target.checked)
                                                                                                                                            let newActiveStates = [...activeStates];
                                                                                                                                            newActiveStates[index][folderIndex] = event.target.checked;
                                                                                                                                            setActiveStates(newActiveStates)
                                                                                                                                        }} />} label="Activo" />
                                                                                                                            </FormGroup>
                                                                                                                        </Grid>
                                                                                                                        {(!folder.id && folder.work_folder !== "upload" && folder.work_folder !== "download") &&
                                                                                                                            <Grid item md={1} sm={1} xs={4}>
                                                                                                                                <IconButton aria-label="eliminar carpeta"
                                                                                                                                    onClick={() => {
                                                                                                                                        arrayHelpers.remove(folderIndex)
                                                                                                                                        const newActiveStates = [...activeStates]
                                                                                                                                        newActiveStates[index].splice(folderIndex, 1)
                                                                                                                                        const newReadStates = [...readStates]
                                                                                                                                        newReadStates[index].splice(folderIndex, 1)
                                                                                                                                        setActiveStates(newActiveStates)
                                                                                                                                        setReadStates(newReadStates)
                                                                                                                                    }}
                                                                                                                                    color="error">
                                                                                                                                    <DeleteIcon />
                                                                                                                                </IconButton>
                                                                                                                            </Grid>
                                                                                                                        }

                                                                                                                        {formik.values.base_folder[index].account_folders.length > 1 && folderIndex !== formik.values.base_folder[index].account_folders.length - 1 &&
                                                                                                                            <Grid item xs={12}>
                                                                                                                                <BoxDivider />
                                                                                                                            </Grid>
                                                                                                                        }

                                                                                                                    </Grid>
                                                                                                                )
                                                                                                            )
                                                                                                            :

                                                                                                            <Grid container item xs={12} justifyContent="center" >
                                                                                                                <Typography sx={{ pt: 4, pb: 4 }} variant="h6">No ha configurado ninguna otra carpeta para esta cuenta.</Typography>
                                                                                                            </Grid>
                                                                                                        }
                                                                                                    </Grid>
                                                                                                    {!isView &&
                                                                                                        <Grid item>
                                                                                                            <IconButton aria-label="agregar carpeta interna"
                                                                                                                onClick={() => {
                                                                                                                    arrayHelpers.insert(formik.values.base_folder[index].account_folders.length,
                                                                                                                        { ...emptyFolder })
                                                                                                                    let newActiveStates = [...activeStates]
                                                                                                                    let newActive = newActiveStates[index]
                                                                                                                    newActive.push(false)
                                                                                                                    setActiveStates(newActiveStates)
                                                                                                                    let newReadStates = [...readStates]
                                                                                                                    let newRead = newReadStates[index]
                                                                                                                    newRead.push(false)
                                                                                                                    setReadStates(newReadStates)
                                                                                                                }}
                                                                                                                color="primary">
                                                                                                                <AddCircleIcon />
                                                                                                            </IconButton>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </CardContent>
                                                                        </Card>
                                                                    </Grid>
                                                                )) :
                                                            <Grid container item xs={12} justifyContent="center" >
                                                                <Typography sx={{ pt: 4, pb: 4 }} variant="h6">No ha configurado ninguna otra carpeta base para esta cuenta.</Typography>
                                                            </Grid>
                                                        }

                                                    </Grid>
                                                    {!isView &&
                                                        <Grid item container md={12} direction="row" spacing={2} justifyContent="center">
                                                            <Grid item>
                                                                <Button variant="contained" startIcon={<AddCircleIcon />}
                                                                    aria-label="agregar carpeta base"
                                                                    onClick={() => {
                                                                        arrayHelpers.insert(formik.values.base_folder.length,
                                                                            { ...emptyBaseFolder })
                                                                        const newSelectedServer = [...selectedServerFolders]
                                                                        newSelectedServer.push(null)
                                                                        setSelectedServerFolders(newSelectedServer)
                                                                        const newActiveFolder = [...activeBaseFolders]
                                                                        newActiveFolder.push(false)
                                                                        setActiveBaseFolders(newActiveFolder)

                                                                        formik.setFieldValue(`base_folder.${formik.values.base_folder.length}.account_folders`, [
                                                                            {
                                                                                work_folder: 'download',
                                                                                reading_folder: false,
                                                                                is_active: true
                                                                            },
                                                                            {
                                                                                work_folder: 'upload',
                                                                                reading_folder: true,
                                                                                is_active: true
                                                                            },
                                                                        ])

                                                                        let newActiveStates = [...activeStates]
                                                                        newActiveStates.push([true, true])
                                                                        setActiveStates(newActiveStates)
                                                                        let newReadStates = [...readStates]
                                                                        newReadStates.push([false, true])
                                                                        setReadStates(newReadStates)
                                                                    }}
                                                                    color="primary">
                                                                    Agregar Carpeta Base
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </React.Fragment>
                                            )} />
                                    </Grid>
                                </Grid>
                            }
                        </CardContent>
                        {!isView &&
                            <CardActions sx={{ ml: 1, pb: 2, pr: 2 }} >
                                <Grid container direction="row" spacing={2} justifyContent="space-between"
                                    alignItems="center"  >
                                    <Grid item>
                                        <Button variant="contained" aria-label="Volver a listado" color="secondary" onClick={() => { onCancel() }} >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" aria-label={getAction()} type={"submit"} disabled={formik.isSubmitting}>
                                            {getAction()}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        }
                    </Card>

                </form>
            )
            }
        </Formik >
    )
}

export default FoldersForm;