import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PasswordInputMUI from "components/PasswordInputMUI";
import { Formik } from 'formik';
import * as Yup from 'yup';

import axios from 'helpers/CustomAxios';
export default function UserAdminPasswordChangeDialog({ open, handleClose,
     username = "",setSuccessMsg, setErrorMsg }) {

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                new_password: '',
            }}
            validationSchema={Yup.object({
                new_password: Yup.string().typeError("Contraseña inválida").required("Campo requerido"),

            })}
            onSubmit={(values, { setSubmitting }) => {
                
                let requestData = {
                    username: username,
                    new_password: values.new_password,
                }
                axios.put("auth/", null, { params: { ...requestData } })
                    .then(function (response) {
                        setSuccessMsg("La contraseña se ha cambiado correctamente.")
                    })
                    .catch(function (error) {
                        console.log(error);
                        setErrorMsg("Ha ocurrido un error");

                    })
                    .then(() => {
                        setSubmitting(false);
                        handleClose()
                    })
            }}
        >
            {formik => (

                <form onSubmit={formik.handleSubmit}>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Cambiar Contraseña</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Ingrese una contraseña nueva para {username}
                            </DialogContentText>
                            <PasswordInputMUI name="new_password" label={"Contraseña Nueva"} required={true} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancelar</Button>
                            <Button  onClick={() => { formik.submitForm(); }}>Aceptar</Button>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        </Formik>
    );
}