import React, { useState, useEffect } from 'react';
import { Grid, Typography, Alert, Card, CardContent, Box, CircularProgress } from "@mui/material";
import axios from 'helpers/AxiosDashboard';
const StatCard = ({ label, value, isBad = false, isTotal = false, note = null }) => {
    return (

        <Card sx={{ backgroundColor: isTotal ? "primary.main" : "background.paper" }}>
            <CardContent>
                <Grid container spacing={2} direction="row" alignItems={"center"}>
                    <Grid item xs={8}>
                        <Typography sx={{ fontSize: isTotal ? 18 : 16 }}
                            color={isTotal ? "secondary.contrastText" : "text.secondary"}
                            fontWeight={isTotal ? "bold" : "regular"}
                            gutterBottom>

                            {label}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            sx={{
                                textAlign: 'right',
                                color: isTotal ? "secondary.contrastText" : isBad ? "error.main" : "info.main"
                            }}
                            variant="h5"
                            fontWeight={isTotal ? "bold" : "regular"}
                            component="div">
                            {value}
                        </Typography>

                    </Grid>
                    {note &&
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    textAlign: 'right',
                                    color: isTotal ? "secondary.contrastText" : isBad ? "error.main" : "info.main"
                                }}
                                variant="h6"
                                fontWeight={isTotal ? "bold" : "regular"}
                                component="div">
                                {note}
                            </Typography>
                        </Grid>
                    }
                </Grid>

            </CardContent>
        </Card>
    )
}
function FileMonitor({ setBackgroundColor }) {
    const [diffTotal, setDiffTotal] = useState(3)
    const [counters, setCounters] = useState(null)
    const [errorMsg, setErrorMsg] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (diffTotal) {
            if (diffTotal >= 10) {
                setBackgroundColor("error.main")
            }
            else if (diffTotal >= 5) {
                setBackgroundColor("warning.main")
            } else {
                setBackgroundColor("background.paper")
            }
        }
        else {
            setBackgroundColor("background.paper")
        }
    }, [diffTotal])

    useEffect(() => {
        const timer = setTimeout(() => {
            axios.get("dashboards/counters")
                .then(function (response) {
                    setCounters(response.data)
                    let diff = response.data.total_entered - response.data.total_outputs - response.data.rejected
                    setDiffTotal(diff ? diff : 0)
                })
                .catch(function (error) {
                    console.log(error);
                    setDiffTotal(0)
                    if (error.response) {
                        if (error.response.status === 404) {
                            setErrorMsg("Error 404: La URL solicitada no se encuentra en el servidor");
                        } else {
                            setErrorMsg("Ha ocurrido un error.");
                        }
                    } else {
                        setErrorMsg("Error: No hay conectividad con el backend.")
                    }

                })
                .finally(() => {
                    setLoading(false)
                })
        }, 5000);
        return () => {
            clearTimeout(timer);
        };

    }, [counters])
    return (
        <Grid container spacing={2}>
            {errorMsg &&
                <Grid item xs={12}>
                    <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                </Grid>
            }
            {loading ?
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, height: "400px", alignItems: "center", }}>
                        <CircularProgress />
                    </Box>
                </Grid>
                :
                <>
                    {counters &&
                        <>
                            <Grid item xs={12} container spacing={2} direction="row" 
                            justifyContent={"space-between"}>
                                <Grid item sm={4} xs={6}>
                                    <StatCard label={"Recibidos"} value={counters.received} />
                                </Grid>
                                <Grid item sm={4} xs={6}>
                                    <StatCard label={"Aceptados"} value={counters.accepted} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container spacing={2} direction="row"
                            justifyContent={"space-between"} >
                                <Grid item sm={4} xs={6}>
                                    <StatCard label={"Anteriores"} value={counters.previous} />
                                </Grid>
                                <Grid item sm={4} xs={6}>
                                    <StatCard label={"Rechazados"} value={counters.rejected} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container spacing={2} direction="row"
                                justifyContent={"space-between"} >
                                <Grid item sm={4} xs={6}>
                                    <StatCard label={"Recuperadas"} value={counters.recovered} />
                                </Grid>
                                <Grid item sm={4} xs={6}>
                                    <StatCard label={"A Futuro"} value={counters.future} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container spacing={2} direction="row"
                                justifyContent={"flex-end"}>

                                <Grid item sm={4} xs={6}>
                                    <StatCard label={"A Tandem"} value={counters.tandem} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container spacing={2} direction="row"
                                justifyContent={"space-between"}>
                                <Grid item sm={4} xs={6}>
                                    <StatCard label={"Total Ingresos"} value={counters.total_entered} isTotal={true} />
                                </Grid>
                                <Grid item sm={4} xs={6}>
                                    <StatCard label={"Total Egresos"} value={counters.total_outputs} isTotal={true}
                                        note={diffTotal !== 0 ? `Diferencia: ${diffTotal} ` : ''}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }
                </>
            }
        </Grid>
    )
}
export default FileMonitor