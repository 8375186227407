import React, { useState, useEffect } from 'react';
import {
    Grid, Button, Alert, Card, CardActions, CardContent,
    FormGroup, FormControlLabel, Checkbox, Typography, IconButton,
    CircularProgress, Box, Autocomplete, TextField
} from "@mui/material";
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import TextInputMUI from 'components/TextInputMUI';
import axios from 'helpers/CustomAxios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import BoxDivider from 'components/BoxDivider'
function EmailForm({ onCancel, onSuccess = null, selectedId, isView = false,
    isEdit = false, isCreate = false }) {
    const [activeStates, setActiveStates] = useState([false]);
    const [errorMsg, setErrorMsg] = useState(null);
    const [initialData, setInitialData] = useState(null);
    const [fullyLoaded, setFullyLoaded] = useState(false)
    const [loadSelect, setLoadSelect] = useState(false)

    useEffect(() => {

        if (selectedId) {
            axios.get("accounts/" + selectedId, {})
                .then(function (response) {
                    let emailsIsActive = []
                    response.data.account_emails.forEach(email => {
                        emailsIsActive.push(email.is_active)
                    });

                    setActiveStates(emailsIsActive)
                    setInitialData(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }).then(() => {
                    setFullyLoaded(true)
                })
        } else {
            setFullyLoaded(true)
        }
    }, [])

    const getAction = () => {
        if (isCreate) {
            return "Crear"
        }
        else if (isEdit) {
            return "Editar"
        }
        else if (isView) {
            return "Ver"
        }
    }

    const emailEmpty = {
        email_address: '',
        email_name: '',
        is_active: false,
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                account_emails: initialData ? initialData.account_emails :
                    [{ ...emailEmpty }]
            }}
            validationSchema={
                Yup.array()
                    .of(
                        Yup.object().shape({
                            email_address: Yup.string().typeError("Campo inválido").required("Campo requerido")
                                .email("E-Mail inválido"),
                            email_name: Yup.string().typeError("Campo inválido").required("Campo requerido"),
                            is_active: Yup.boolean().typeError("Campo inválido"),
                         })
                    )}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                let requestData = { ...initialData };
                requestData.account_emails = values.account_emails;
                if (isEdit) {
                    axios.put("accounts/" + selectedId, requestData)
                        .then(function (response) {
                            onSuccess(initialData.accountname + " se ha editado correctamente.")
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                            setSubmitting(false);
                        })
                }

            }}
        >
            {formik => (

                <form onSubmit={formik.handleSubmit}>

                    <Card >
                        <CardContent>
                            {!fullyLoaded ?
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, height: "400px", alignItems: "center", }}>
                                    <CircularProgress />
                                </Box> :
                                <Grid container spacing={2} alignContent="center">
                                    {errorMsg &&
                                        <Grid item xs={12}>
                                            <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                                        </Grid>
                                    }
                                    <FieldArray name="account_emails"
                                        render={arrayHelpers => (
                                            <React.Fragment>
                                                <Grid container item spacing={2} direction="row" alignItems="center" md={isView ? 12 : 8} sm={12} sx={{ pt: 2, pb: 2 }}>
                                                    <Grid item>
                                                        <Typography variant="h6">Configurar E-Mails</Typography>
                                                    </Grid>
                                                    {!isView &&
                                                        <Grid item>
                                                            <IconButton aria-label="agregar e-mail"
                                                                onClick={() => {
                                                                    arrayHelpers.insert(formik.values.account_emails.length, { ...emailEmpty })
                                                                    const newActiveStates = [...activeStates]
                                                                    newActiveStates.push(false)
                                                                    setActiveStates(newActiveStates)
                                                                }}
                                                                color="primary">
                                                                <AddCircleIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    }
                                                </Grid>
                                                <Grid container item md={12} sm={12} xs={12} spacing={2} sx={{ pb: 2 }}>

                                                    {formik.values.account_emails && formik.values.account_emails.length > 0 ?
                                                        (
                                                            formik.values.account_emails.map((email, index) =>
                                                                <Grid key={index} item container alignContent="center" xs={12} direction="row" spacing={2}>
                                                                    <Grid item lg={3} md={6} xs={12}>
                                                                        <TextInputMUI name={`account_emails.${index}.email_name`} label={"Nombre"} required={true} isView={isView} />
                                                                    </Grid>
                                                                    <Grid item lg={3} md={6} xs={12}>
                                                                        <TextInputMUI name={`account_emails.${index}.email_address`} label={"Dirección"} required={true} isView={isView} />
                                                                    </Grid>                                                                   
                                                                    <Grid item lg={2} md={3} xs={6}>
                                                                        <FormGroup>
                                                                            <FormControlLabel disabled={isView} control={<Checkbox checked={activeStates[index]}
                                                                                onChange={(event) => {
                                                                                    formik.setFieldValue(`account_emails.${index}.is_active`, event.target.checked)
                                                                                    let newActiveStates = [...activeStates];
                                                                                    newActiveStates[index] = event.target.checked;
                                                                                    setActiveStates(newActiveStates)
                                                                                }} />} label="Activo" />
                                                                        </FormGroup>
                                                                    </Grid>
                                                                    {!email.id &&
                                                                        <Grid item lg={2} md={3} xs={6}>
                                                                            <IconButton aria-label="eliminar e-mail"
                                                                                onClick={() => {
                                                                                    setLoadSelect(false)
                                                                                    arrayHelpers.remove(index)
                                                                                    const newActiveStates = [...activeStates]
                                                                                    newActiveStates.splice(index, 1)
                                                                                    setActiveStates(newActiveStates)

                                                                                }}
                                                                                color="error">
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    }
                                                                    {formik.values.account_emails.length > 1 && index !== formik.values.account_emails.length - 1 &&
                                                                        <Grid item xs={12}>
                                                                            <BoxDivider />
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            )
                                                        )
                                                        :

                                                        <Grid container item xs={12} justifyContent="center" >
                                                            <Typography sx={{ pt: 4, pb: 4 }} variant="h6">No ha configurado ningún e-mail para esta cuenta.</Typography>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </React.Fragment>
                                        )} />
                                </Grid>
                            }
                        </CardContent>
                        {!isView &&
                            <CardActions sx={{ ml: 1, pb: 2, pr: 2 }} >
                                <Grid container direction="row" spacing={2} justifyContent="space-between"
                                    alignItems="center"  >
                                    <Grid item>
                                        <Button variant="contained" aria-label="Volver a listado" color="secondary" onClick={() => { onCancel() }} >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" aria-label={getAction()} type={"submit"} disabled={formik.isSubmitting}>
                                            {getAction()}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        }
                    </Card>

                </form>
            )}
        </Formik>
    )
}

export default EmailForm;