export const adminPermissions = [
    //DASHBOARDS
    "fileMonitor",
    "fileGrid",
    "modules",

    //ACCOUNT
    "accounts",

    // CONFIGURATION
    "designs",
    "holidays",
    "dateformats",
    "validationgroupadministration",
    "headings",
    "specialnames",
    "outputdesign",
    "servers",

    // CONFIGURATION -> EMAILS
    "emailgroups",
    "emailtemplates",
    "emailgroupstemplates",
    

    //CONFIGURATION -> FILES
    "filedatainfo",
    "fileencodings",
    "filemasks",
    "fileprocesstype",
    "fileschedules",
    "filesequences",

    // CONFIGURATION -> PARAMETERS
    "parameters",
    "parameterTypes",
    "parameterModules",

    //SEGURIDAD
    "levels",
    "users"
]

export const operacionesPermissions = [
    //DASHBOARDS
    "fileMonitor",
    "fileGrid",

    //ACCOUNT
    "accounts",
]

export const viewerPermissions = [
    //DASHBOARDS
    "fileMonitor",
    "fileGrid",

    //ACCOUNT
    "accounts",
]