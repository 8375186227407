import { Buffer } from 'buffer';
import {parse, isDate} from 'date-fns'

export const parseJwt = (token) => {
  let base64Url = token.split('.')[1]; // token you get
  let base64 = base64Url.replace('-', '+').replace('_', '/');
  let decodedData = JSON.parse(Buffer.from(base64, 'base64').toString('binary'));

  decodedData.sub = decodedData.sub.replaceAll("'", '"')
  return JSON.parse(decodedData.sub)
}

export const parseDateString = (value, originalValue) => {
  let parsedDate = isDate(originalValue)
  ? originalValue 
  : parse(originalValue, "dd/MM/yyyy", new Date());
  let isNotDate = isNaN(parsedDate)
  if(isNotDate){
    parsedDate = isDate(originalValue)
    ? originalValue 
    : parse(originalValue, "yyyy-MM-dd", new Date());
    isNotDate = isNaN(parsedDate)
  }
  parsedDate = !isNotDate ? parsedDate : null
  return parsedDate
}


export const GridLocalization = {
  // Root
  noRowsLabel: 'No hay registros',
  noResultsOverlayLabel: 'No se han encontrado registros.',
  errorOverlayDefaultLabel: 'Ha ocurrido un error.',

  // Density selector toolbar button text
  toolbarDensity: 'Densidad',
  toolbarDensityLabel: 'Densidad',
  toolbarDensityCompact: 'Compacta',
  toolbarDensityStandard: 'Estandar',
  toolbarDensityComfortable: 'Comfortable',

  // Columns selector toolbar button text
  toolbarColumns: 'Columnas',
  toolbarColumnsLabel: 'Seleccionar columnas',

  // Filters toolbar button text
  toolbarFilters: 'Filtros',
  toolbarFiltersLabel: 'Mostrar filtros',
  toolbarFiltersTooltipHide: 'Ocultar filtros',
  toolbarFiltersTooltipShow: 'Mostrar filtros',
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros activos` : `${count} filtro activo`,

  // Export selector toolbar button text
  toolbarExport: 'Exportae',
  toolbarExportLabel: 'Exportae',
  toolbarExportCSV: 'Descargar CSV',
  toolbarExportPrint: 'Imprimir',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Buscar columna',
  columnsPanelTextFieldPlaceholder: 'Titulo de columna',
  columnsPanelDragIconLabel: 'Reordenar columna',
  columnsPanelShowAllButton: 'Mostrar todas',
  columnsPanelHideAllButton: 'Esconder todas',

  // Filter panel text
  filterPanelAddFilter: 'Agregar filtro',
  filterPanelDeleteIconLabel: 'Eliminar',
  filterPanelOperators: 'Operadores',
  filterPanelOperatorAnd: 'Y',
  filterPanelOperatorOr: 'O',
  filterPanelColumns: 'Columnas',
  filterPanelInputLabel: 'Valor',
  filterPanelInputPlaceholder: 'Valor de filtro',

  // Filter operators text
  filterOperatorContains: 'Contiene',
  filterOperatorEquals: 'Igual a',
  filterOperatorStartsWith: 'Empieza con',
  filterOperatorEndsWith: 'Termina en',
  filterOperatorIs: 'es',
  filterOperatorNot: 'no es',
  filterOperatorAfter: 'despues de',
  filterOperatorOnOrAfter: 'esta en o despues de',
  filterOperatorBefore: 'antes de',
  filterOperatorOnOrBefore: 'esta en o antes de',
  filterOperatorIsEmpty: 'esta vacio',
  filterOperatorIsNotEmpty: 'no esta vacio',
  filterOperatorIsAnyOf: 'no es ninguno de',

  // Filter values text
  filterValueAny: 'cualquiera',
  filterValueTrue: 'verdadero',
  filterValueFalse: 'falso',

  // Column menu text
  columnMenuLabel: 'Menu',
  columnMenuShowColumns: 'Mostrar columnas',
  columnMenuFilter: 'Filtros',
  columnMenuHideColumn: 'Ocultar',
  columnMenuUnsort: 'Quitar ordenamiento',
  columnMenuSortAsc: 'Ordenar ASC',
  columnMenuSortDesc: 'Ordenar DESC',

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros activos` : `${count} filtro activo`,
  columnHeaderFiltersLabel: 'Mostrar filtros',
  columnHeaderSortIconLabel: 'Ordenar',

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} filas seleccionadas`
      : `${count.toLocaleString()} fila seleccionada`,

  // Total rows footer text
  footerTotalRows: 'Total filas:',

  // Total visible rows footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Seleccion checkbox',
  checkboxSelectionSelectAllRows: 'Seleccionar todas las filas',
  checkboxSelectionUnselectAllRows: 'Deseleccionar todas las filas',
  checkboxSelectionSelectRow: 'Seleccionar fila',
  checkboxSelectionUnselectRow: 'Deseleccionar fila',

  // Boolean cell text
  booleanCellTrueLabel: 'si',
  booleanCellFalseLabel: 'no',

  // Actions cell more text
  actionsCellMore: 'más',

  // Column pinning text
  pinToLeft: 'Fijar a la izquierda',
  pinToRight: 'Fijar a la derecha',
  unpin: 'Desfijar',

  // Tree Data
  treeDataGroupingHeaderName: 'Grupos',
  treeDataExpand: 'ver ocultar hijos',

  // Grouping columns
  groupingColumnHeaderName: 'Agrupar',
  groupColumn: (name) => `Agrupar por ${name}`,
  unGroupColumn: (name) => `Dejar de agrupar por ${name}`,

  // Master/detail
  expandDetailPanel: 'Expandir',
  collapseDetailPanel: 'Colapsar',
}