import React, { useState, useEffect } from 'react';
import { Grid, Button, Alert, Card, CardActions, CardContent, CardHeader, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInputMUI from 'components/TextInputMUI';
import axios from 'helpers/CustomAxios';

function FileDataInfoForm({ onCancel, onSuccess = null, selectedId, isView = false, isEdit = false, isCreate = false }) {
    const [active, setActive] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null);
    const [initialData, setInitialData] = useState(null);

    useEffect(() => {
        if (selectedId) {
            axios.get("filedatainfo/" + selectedId, {})
                .then(function (response) {
                    setInitialData(response.data)
                    setActive(response.data.is_active)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                })
        }
    }, [])

    const getAction = () => {
        if (isCreate) {
            return "Crear"
        }
        else if (isEdit) {
            return "Editar"
        }
        else if (isView) {
            return "Ver"
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                name: initialData ? initialData.name : '',
                show_order: initialData ? initialData.show_order : 0,
                is_active: initialData ? initialData.is_active : false
            }}
            validationSchema={Yup.object({
                name: Yup.string().typeError("Nombre inválido").required("Campo requerido"),
                show_order: Yup.number().typeError("Ingrese un número entero").integer("Ingrese un número entero").min(0, "Ingrese un número igual o mayor a 0").required("Campo requerido"),
            })}
            onSubmit={(values, { setSubmitting }) => {
                
                values.id = selectedId ? selectedId : values.show_order
                if (isCreate) {
                    axios.post("filedatainfo/", values)
                        .then(function (response) {
                            onSuccess(values.name + " se ha creado correctamente.")
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                            setSubmitting(false);
                        })
                } else if (isEdit) {
                    axios.put("filedatainfo/" + selectedId, values)
                        .then(function (response) {
                            onSuccess(values.name + " se ha editado correctamente.")
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                            setSubmitting(false);
                        })
                }


            }}
        >
            {formik => (

                <form onSubmit={formik.handleSubmit}>

                    <Card>
                        <CardHeader title={getAction() + " Servidor"} />
                        <CardContent>
                            <Grid container spacing={2} alignContent="center">
                                {errorMsg &&
                                    <Grid item xs={12}>
                                        <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                                    </Grid>
                                }
                                <Grid item md={5} sm={12} xs={12}>
                                    <TextInputMUI name="name" label={"Nombre"} required={true} isView={isView} />
                                </Grid>
                                <Grid item md={5} sm={12} xs={12}>
                                    <TextInputMUI type="number" name="show_order" label={"show_order"} required={true} isView={isView} />
                                </Grid>
                                <Grid item md={2} sm={12} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel disabled={isView} control={<Checkbox checked={active} onChange={(event) => {
                                            formik.setFieldValue("is_active", event.target.checked)
                                            setActive(event.target.checked)
                                        }} />} label="Activo" />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions sx={{ ml: 1, pb: 2, pr: 2 }} >
                            <Grid container direction="row" spacing={2} justifyContent="space-between"
                                alignItems="center"  >

                                {isView ?
                                    <Grid item>
                                        <Button variant="contained" aria-label="Volver a listado" color="primary" onClick={() => { onCancel() }}>
                                            Volver
                                        </Button>
                                    </Grid>
                                    :
                                    <>
                                        <Grid item>
                                            <Button variant="contained" aria-label="Cancelar" color="secondary" onClick={() => { onCancel() }} disabled={formik.isSubmitting}>
                                                Cancelar
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" aria-label={getAction()} type={"submit"} disabled={formik.isSubmitting}>
                                                {getAction()}
                                            </Button>
                                        </Grid>
                                    </>

                                }
                            </Grid>
                        </CardActions>
                    </Card>

                </form>
            )}
        </Formik>
    )
}

export default FileDataInfoForm;