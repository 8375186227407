import React, { useState, useEffect } from 'react';
import {
    Grid, Button, Alert, Card, CardActions, CardContent, CardHeader,
    FormGroup, FormControlLabel, Checkbox, IconButton, Typography
} from "@mui/material";
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import TextInputMUI from 'components/TextInputMUI';
import axios from 'helpers/CustomAxios';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

function ServerForm({ onCancel, onSuccess = null, selectedId, isView = false, isEdit = false, isCreate = false }) {
    const [active, setActive] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null);
    const [initialData, setInitialData] = useState(null);

    useEffect(() => {
        if (selectedId) {
            axios.get("servers/" + selectedId, {})
                .then(function (response) {
                    console.log(response)
                    setInitialData(response.data)
                    setActive(response.data.is_active)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                })
        }
    }, [])

    const getAction = () => {
        if (isCreate) {
            return "Crear"
        }
        else if (isEdit) {
            return "Editar"
        }
        else if (isView) {
            return "Ver"
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                servername: initialData ? initialData.servername : '',
                server_ip: initialData ? initialData.server_ip : '',
                is_active: initialData ? initialData.is_active : false,
                folders: initialData ? initialData.folders : []
            }}
            validationSchema={Yup.object({
                servername: Yup.string().typeError("Nombre inválido").required("Campo requerido"),
                server_ip: Yup.string().typeError("IP inválida").required("Campo requerido")
                    .matches(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/, "IP inválida")
            })}
            onSubmit={(values, { setSubmitting }) => {
                console.log(values)
                if (isCreate) {
                    axios.post("servers/", values)
                        .then(function (response) {
                            onSuccess("El servidor " + values.servername + " se ha creado correctamente.")
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                            setSubmitting(false);
                        })
                } else if (isEdit) {
                    axios.put("servers/" + selectedId, values)
                        .then(function (response) {
                            onSuccess("El servidor " + values.servername + " se ha editado correctamente.")
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                            setSubmitting(false);
                        })
                }


            }}
        >
            {formik => (

                <form onSubmit={formik.handleSubmit}>

                    <Card>
                        <CardHeader title={getAction() + " Servidor"} />
                        <CardContent>
                            <Grid container spacing={2} alignContent="center">
                                {errorMsg &&
                                    <Grid item xs={12}>
                                        <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                                    </Grid>
                                }
                                <Grid item md={5} sm={12} xs={12}>
                                    <TextInputMUI name="servername" label={"Nombre"} required={true} isView={isView} />
                                </Grid>
                                <Grid item md={5} sm={12} xs={12}>
                                    <TextInputMUI name="server_ip" label={"IP"} required={true} isView={isView} />
                                </Grid>
                                <Grid item md={2} sm={12} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel disabled={isView} control={<Checkbox checked={active} onChange={(event) => {
                                            formik.setFieldValue("is_active", event.target.checked)
                                            setActive(event.target.checked)
                                        }} />} label="Activo" />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            {(isEdit || isView) &&

                                <FieldArray name="folders"
                                    render={arrayHelpers => (
                                        <React.Fragment>
                                            <Grid container item spacing={2} direction="row" alignItems="center" md={isView ? 12 : 8} sm={12} sx={{ pt: 2, pb: 2 }}>
                                                <Grid item>
                                                    <Typography variant="h6">Carpetas</Typography>
                                                </Grid>
                                                {isEdit &&
                                                    <Grid item>
                                                        <IconButton aria-label="agregar ruta de carpeta"
                                                            onClick={() => arrayHelpers.insert(formik.values.folders.length, { folder_path: "", server_id: selectedId, id: null })} color="primary">
                                                            <AddCircleIcon />
                                                        </IconButton>
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Grid container item md={12} sm={12} xs={12} spacing={2} sx={{ pb: 2 }}>

                                                {formik.values.folders && formik.values.folders.length > 0 ?
                                                    (
                                                        formik.values.folders.map((folder, index) =>
                                                            <Grid item container alignContent="center" md={isView ? 12 : 8} sm={12} direction="row" spacing={2}>
                                                                <Grid item xs={10}>
                                                                    <TextInputMUI name={`folders.${index}.folder_path`} label={"Ruta"} required={true} isView={isView} />
                                                                </Grid>
                                                                {isEdit &&
                                                                    <Grid item xs={2}>
                                                                        <IconButton aria-label="eliminar ruta de carpeta"
                                                                            onClick={() => arrayHelpers.remove(index)} color="error">
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        )
                                                    )
                                                    :

                                                    <Grid container item xs={12} justifyContent="center" >
                                                        <Typography sx={{ pt: 4, pb: 4 }} variant="h6">No ha configurado ninguna carpeta.</Typography>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </React.Fragment>
                                    )} />

                            }

                        </CardContent>
                        <CardActions sx={{ ml: 1, pb: 2, pr: 2 }} >
                            <Grid container direction="row" spacing={2} justifyContent="space-between"
                                alignItems="center"  >

                                {isView ?
                                    <Grid item>
                                        <Button variant="contained" aria-label="Volver a listado" color="primary" onClick={() => { onCancel() }}>
                                            Volver
                                        </Button>
                                    </Grid>
                                    :
                                    <>
                                        <Grid item>
                                            <Button variant="contained" aria-label="Cancelar" color="secondary" onClick={() => { onCancel() }} disabled={formik.isSubmitting}>
                                                Cancelar
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" aria-label={getAction() + " servidor"} type={"submit"} disabled={formik.isSubmitting}>
                                                {getAction()}
                                            </Button>
                                        </Grid>
                                    </>

                                }
                            </Grid>
                        </CardActions>
                    </Card>

                </form>
            )}
        </Formik>
    )
}

export default ServerForm;