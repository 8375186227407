import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Box, Alert, Tooltip, FormControlLabel } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SpecialNameForm from './SpecialNameForm';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { GridLocalization } from 'helpers/general';
import Switch from '@mui/material/Switch';
import axios from 'helpers/CustomAxios';
import CustomPagination from 'components/CustomPagination';
function SpecialNameAdministration() {
    const components = ["main", "create", "edit", "view"];
    const [openComponent, setOpenComponent] = useState(components[0]);
    const [data, setData] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [page, setPage] = useState(0);
    const [loadingData, setLoadingData] = useState(true);
    const [permissions, setPermissions] = useState({
        edit: true,
        view: true,
        create: true,
    })

    const [selectedId, setSelectedId] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        setSuccessMsg(null);
        setErrorMsg(null);
    }, [openComponent])


    useEffect(() => {
        loadData();
    }, [pageSize, page])

    const columns = [
        { field: 'fiid', headerName: 'fiid', flex: 1, sortable: false },
        { field: 'body_from', headerName: 'body_from', flex: 1, sortable: false },
        { field: 'body_len', headerName: 'body_len', flex: 1, sortable: false },
        { field: 'date_from', headerName: 'date_from', flex: 1, sortable: false },
        { field: 'date_len', headerName: 'date_len', flex: 1, sortable: false },
        { field: 'date_format', headerName: 'date_format', flex: 1, sortable: false },
        { field: 'sequence_from', headerName: 'sequence_from', flex: 1, sortable: false },
        { field: 'sequence_len', headerName: 'sequence_len', flex: 1, sortable: false },
        { field: 'text_from', headerName: 'text_from', flex: 1, sortable: false },
        { field: 'text_len', headerName: 'text_len', flex: 1, sortable: false },
        {
            field: 'actions', type: 'actions', headerName: "Acciones", flex: 2,
            getActions: (params) => loadActions(params)
        }
    ];

    const loadActions = (params) => {
        let actions = [];
        if (permissions.view) {
            actions.push(<GridActionsCellItem
                icon={
                    <Tooltip title="Ver detalle" placement="top" arrow>
                        <VisibilityIcon aria-label="Ver detalle" color="primary" />
                    </Tooltip>
                }
                label="Ver Detalle"
                onClick={() => {
                    setSelectedId(params.row.id)
                    setOpenComponent(components[3])
                }}
            />)
        }
        if (permissions.edit) {
            actions.push(
                <GridActionsCellItem
                    icon={
                        <Tooltip title="Editar" placement="top" arrow>
                            <EditIcon aria-label="Editar" color="primary" />
                        </Tooltip>}
                    label="Editar"
                    onClick={() => {
                        setSelectedId(params.row.id)
                        setOpenComponent(components[2])
                    }}
                    color="primary"
                />)
        }
        return (actions)
    }



    const loadData = () => {
        setLoadingData(true)
        axios.get("specialname/", { params: { page: page + 1, size: pageSize } })
            .then(function (response) {
                setData(response.data.items);
                setTotalRecords(response.data.total)
            })
            .catch(function (error) {
                console.log(error);
                setErrorMsg("Ha ocurrido un error");
            })
            .then(function () {
                setLoadingData(false)
            })
    }

    const backToList = (success = null) => {
        setOpenComponent(components[0])
        if (success) {
            setSuccessMsg(success)
        }
        loadData();
    }

    return (
        <>
            {openComponent === components[0] &&
                <Grid container spacing={2}>
                    <Grid item xs={12} container spacing={2} direction="row" justifyContent={{ xs: "center", sm: "space-between" }} alignItems="center">
                        <Grid item md={9} sm={8} xs={12}>
                            <Typography variant="h4">Configurar Nombres Especiales</Typography>
                        </Grid>
                        {permissions.create &&
                            <Grid item md={3} sm={4} xs={12}>
                                <Box display="flex" justifyContent={{ xs: "center", sm: "flex-end" }}>
                                    <Button aria-label="Crear nuevo nombre especial" variant="contained" size="large" startIcon={<AddCircleIcon />}
                                        onClick={() => { setOpenComponent(components[1]) }}>
                                        Nuevo Nombre Especial
                                    </Button>
                                </Box>
                            </Grid>
                        }
                        {errorMsg &&
                            <Grid item xs={12}>
                                <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                            </Grid>
                        }
                        {successMsg &&
                            <Grid item xs={12}>
                                <Alert severity="success" onClose={() => { setSuccessMsg(null) }} sx={{ width: '100%' }}>{successMsg}</Alert>
                            </Grid>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <div style={{ height: 450, width: '100%' }}>
                            <div style={{ display: 'flex', height: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                    <DataGrid
                                        localeText={GridLocalization}
                                        density="compact"
                                        disableColumnFilter
                                        pagination
                                        paginationMode="server"
                                        components={{
                                            Pagination: CustomPagination,
                                        }}
                                        rowCount={totalRecords}
                                        loading={loadingData}
                                        pageSize={pageSize}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        page={page}
                                        onPageChange={(newPage) => setPage(newPage)}
                                        rows={data}
                                        columns={columns} />
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            }
            {openComponent === components[1] &&
                <SpecialNameForm onSuccess={backToList} onCancel={backToList} isCreate={true} />
            }
            {openComponent === components[2] &&
                <SpecialNameForm onSuccess={backToList} onCancel={backToList} isEdit={true} selectedId={selectedId} />
            }
            {openComponent === components[3] &&
                <SpecialNameForm onCancel={backToList} isView={true} selectedId={selectedId} />
            }
        </>

    )
}

export default SpecialNameAdministration;