import React, { useState, useEffect } from 'react';
import { Grid, Alert, Card, CardHeader, IconButton, Box, CircularProgress } from "@mui/material";
import axios from 'helpers/CustomAxios';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DialogConfirmAction from 'components/DialogConfirmAction';
import PauseIcon from '@mui/icons-material/Pause';
import LoadingButton from '@mui/lab/LoadingButton';
const ModuleControl = ({ data, onChangeModuleState }) => {
    const [showConfirmChangeState, setShowConfirmChangeState] = useState(false);
    const getStateName = () => {
        switch (data.status_key) {
            case 1:
                return "Activo"
            case 2:
                return "Activando..."
            case 3:
                return "Pausado"
            case 4:
                return "Pausando..."
            default:
                break;
        }
    }
    return (

        <Card>
            <DialogConfirmAction
                open={showConfirmChangeState}
                header={(data.status_key === 1 ? "Pausar" : "Iniciar") + " " + data.module_key}
                message={"¿Está seguro que desea " + (data.status_key === 1 ? "pausar" : "iniciar") + " " + data.module_key + "?"}
                onClose={() => { setShowConfirmChangeState(false); }}
                onAccept={() => { setShowConfirmChangeState(false); onChangeModuleState(data); }}
                labelAccept={data.status_key === 1 ? "Pausar" : "Iniciar"}

            />
            <CardHeader
                action={
                    data.status_key === 2 || data.status_key === 4 ?
                        <LoadingButton loading variant="text" sx={{ minWidth: 0 }}>
                            <PauseIcon />
                        </LoadingButton>
                        :
                        <IconButton
                            aria-label={"cambiar estado de " + data.module_key}
                            color={data.status_key === 1 ? "error" : "primary"}
                            onClick={() => setShowConfirmChangeState(true)}>
                            {data.status_key === 1 ? <PauseIcon /> : <PlayArrowIcon />}
                        </IconButton>
                }
                title={data.module_key}
                subheader={getStateName()}
            />
        </Card>
    )
}
function AdminModules() {
    const [modules, setModules] = useState(null)
    const [errorMsg, setErrorMsg] = useState(null);


    useEffect(() => {
        const timer = setTimeout(() => {
            loadData();
        }, 5000);
        return () => {
            clearTimeout(timer);
        };
    }, [modules])

    const loadData = () => {
        axios.get("processmanager/")
            .then(function (response) {
                let sorted = response.data.sort((a, b) => a.id - b.id)
                setModules(sorted)
            })
            .catch(function (error) {
                console.log(error);
                setErrorMsg("Ha ocurrido un error");
            })
    }

    const onChangeModuleState = (moduleData) => {
        axios.post("processmanager/" + moduleData.module_key + "/" + (moduleData.status_key === 1 ? 4 : 2), {})
            .then(function (response) {
                loadData()
            })
            .catch(function (error) {
                console.log(error);
                setErrorMsg("Ha ocurrido un error");
            })
    }

    return (
        <Grid container spacing={2}>
            {errorMsg &&
                <Grid item xs={12}>
                    <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                </Grid>
            }
            {!modules ?
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, height: "400px", alignItems: "center", }}>
                        <CircularProgress />
                    </Box>
                </Grid>
                :
                <>
                    <Grid item xs={12} container spacing={2} direction="row" >
                        {modules.map((item, index) => (
                            <Grid key={item.id} item md={4} sm={6} xs={12}>
                                <ModuleControl data={item} onChangeModuleState={onChangeModuleState} />
                            </Grid>
                        ))}
                    </Grid>
                </>
            }
        </Grid>
    )
}
export default AdminModules