import React, { useState, useEffect } from 'react';
import {
    Grid, Button, Alert, Card, CardActions, CardContent, CardHeader,
    FormGroup, FormControlLabel, Checkbox, CircularProgress, Box, Typography, Autocomplete, TextField,
    IconButton
} from "@mui/material";
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import TextInputMUI from 'components/TextInputMUI';
import axios from 'helpers/CustomAxios';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BoxDivider from 'components/BoxDivider';

function AccountForm({ onCancel, onSuccess = null, selectedId, isView = false,
    isEdit = false, isCreate = false }) {
    const [active, setActive] = useState(false);
    const [specialName, setSpecialName] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [initialData, setInitialData] = useState(null);
    const [fullyLoaded, setFullyLoaded] = useState(false)
    const [serverFolderOptions, setServerFolderOptions] = useState([null]);
    const [selectedServerFolders, setSelectedServerFolders] = useState(['']);

    const [activeBaseFolders, setActiveBaseFolders] = useState([false]);
    useEffect(() => {
        let serverFolderFormatted = null
        axios.get("serversfolders/combo")
            .then(function (response) {
                serverFolderFormatted = response.data
                setServerFolderOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
                setErrorMsg("Ha ocurrido un error");
            })
            .then(() => {
                if (selectedId) {
                    axios.get("accounts/" + selectedId, {})
                        .then(function (response) {
                            setInitialData(response.data)
                            setActive(response.data.is_active)
                            setSpecialName(response.data.special_name)
                            let serverFolders = []
                            let activeFolders = []
                            response.data.base_folder.forEach(folder => {
                                serverFolders.push(serverFolderFormatted.find(o => o.value === folder.server_folder_id));
                                activeFolders.push(folder.is_active)
                            });
                            setSelectedServerFolders(serverFolders)
                            setActiveBaseFolders(activeFolders)
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                        })
                        .then(() => {
                            setFullyLoaded(true)
                        })
                } else {
                    setFullyLoaded(true)
                }
            })
    }, [selectedId])

    const getAction = () => {
        if (isCreate) {
            return "Crear"
        }
        else if (isEdit) {
            return "Editar"
        }
        else if (isView) {
            return "Ver"
        }
    }

    const emptyBaseFolder = {
        base_folder: '',
        is_active: false,
        server_folder_id: null,
        account_folders: []
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                id: selectedId,
                accountname: initialData ? initialData.accountname : '',
                account_fiid: initialData ? initialData.account_fiid : '',
                encrypt_public_key: initialData ? initialData.encrypt_public_key : '',
                encrypt_private_key: initialData ? initialData.encrypt_private_key : '',
                notes: initialData ? initialData.notes : '',
                special_name: initialData ? initialData.special_name : false,
                is_active: initialData ? initialData.is_active : false,
                base_folder: initialData?.base_folder ? initialData.base_folder : [{ ...emptyBaseFolder }]
            }}
            validationSchema={Yup.object({
                accountname: Yup.string().typeError("Campo inválido").required("Campo requerido"),
                account_fiid: Yup.string().typeError("Campo inválido").required("Campo requerido"),
                encrypt_public_key: Yup.string().typeError("Campo inválido"),
                encrypt_private_key: Yup.string().typeError("Campo inválido"),
                notes: Yup.string().typeError("Campo inválido"),
                special_name: Yup.boolean().typeError("Campo inválido"),
                is_active: Yup.boolean().typeError("Campo inválido"),
                base_folder:
                    Yup.array().of(
                        Yup.object().shape(
                            {
                                base_folder: Yup.string().typeError("Campo inválido").required("Campo requerido"),
                                is_active: Yup.boolean().typeError("Campo inválido"),
                                server_folder_id: Yup.number().required("Campo requerido"),
                                account_folders: Yup.array()
                                    .of(
                                        Yup.object().shape({
                                            work_folder: Yup.string().typeError("Campo inválido").required("Campo requerido"),
                                            reading_folder: Yup.boolean().typeError("Campo inválido"),
                                            is_active: Yup.boolean().typeError("Campo inválido"),
                                        })
                                    )
                            })
                    ),
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                values.base_folder.base_folder = values.accountname
                if (isCreate) {
                    axios.post("accounts/", values)
                        .then(function (response) {
                            onSuccess(values.accountname + " se ha creado correctamente.", response.data.id)
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                        })
                        .then(() => {
                            setSubmitting(false);
                        })
                } else if (isEdit) {
                    axios.put("accounts/" + selectedId, values)
                        .then(function (response) {
                            onSuccess(values.accountname + " se ha editado correctamente.")
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");

                        })
                        .then(() => {
                            setSubmitting(false);
                        })
                }


            }}
        >
            {formik => (

                <form onSubmit={formik.handleSubmit}>

                    <Card >
                        <CardHeader title={getAction() + " Cuenta"} />
                        <CardContent>
                            {!fullyLoaded ?
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, height: "400px", alignItems: "center", }}>
                                    <CircularProgress />
                                </Box> :
                                <Grid container spacing={2} alignContent="center">
                                    {errorMsg &&
                                        <Grid item xs={12}>
                                            <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                                        </Grid>
                                    }
                                    <Grid item container md={12} direction="row" spacing={2}>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <TextInputMUI name="accountname" label={"Nombre"} required={true} isView={isView} />
                                        </Grid>
                                        <Grid item md={3} sm={3} xs={12}>
                                            <FormGroup>
                                                <FormControlLabel disabled={isView} control={<Checkbox checked={active}
                                                    onChange={(event) => {
                                                        formik.setFieldValue("is_active", event.target.checked)
                                                        setActive(event.target.checked)
                                                    }} />} label="Activo" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item md={3} sm={3} xs={12}>
                                            <FormGroup>
                                                <FormControlLabel disabled={isView} control={<Checkbox checked={specialName}
                                                    onChange={(event) => {
                                                        formik.setFieldValue("special_name", event.target.checked)
                                                        setSpecialName(event.target.checked)
                                                    }} />} label="Nombre Especial" />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid item container md={12} direction="row" spacing={2}>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <TextInputMUI name="account_fiid" label={"Fiid"} required={true} isView={isView} />
                                        </Grid>
                                    </Grid>
                                    <Grid item container md={12} direction="row" spacing={2}>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <TextInputMUI name="encrypt_public_key" label={"Llave de Encriptación Publica"} required={false} isView={isView} />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <TextInputMUI name="encrypt_private_key" label={"Llave de Encriptación Privada"} required={false} isView={isView} />
                                        </Grid>
                                    </Grid>
                                    <FieldArray name="base_folder"
                                        render={arrayHelpers => (
                                            <React.Fragment>
                                                <Grid item container md={12} direction="row" spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6">Carpetas Base</Typography>
                                                    </Grid>
                                                    {formik.values.base_folder && formik.values.base_folder.length > 0 ?
                                                        (
                                                            formik.values.base_folder.map((folder, index) =>
                                                                <Grid key={index} item container alignContent="center" xs={12} direction="row" spacing={2}>

                                                                    <Grid item sm={4} xs={12}>
                                                                        <Autocomplete
                                                                            disablePortal
                                                                            value={selectedServerFolders[index]}
                                                                            onChange={(event, newValue) => {
                                                                                let newSelectedServer = [...selectedServerFolders]
                                                                                newSelectedServer[index] = newValue
                                                                                setSelectedServerFolders(newSelectedServer)
                                                                                formik.setFieldValue(`base_folder.${index}.server_folder_id`, newValue?.value)
                                                                            }}
                                                                            id="combo-server-folder"
                                                                            disabled={isView}
                                                                            options={serverFolderOptions}
                                                                            renderInput={(params) =>
                                                                                <TextField {...params} disabled={isView} required
                                                                                    label="Carpeta de Servidor" variant={isView ? "filled" : "outlined"} />}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item sm={4} xs={12}>
                                                                        <TextInputMUI name={`base_folder.${index}.base_folder`} label={"Ruta"} required={true} isView={isView} />
                                                                    </Grid>
                                                                    <Grid container item sm={2} xs={6} alignContent="center">
                                                                        <FormGroup >
                                                                            <FormControlLabel disabled={isView}
                                                                                control={<Checkbox checked={activeBaseFolders[index]}
                                                                                    onChange={(event) => {
                                                                                        formik.setFieldValue(`base_folder.${index}.is_active`, event.target.checked)
                                                                                        let newActiveFolders = [...activeBaseFolders]
                                                                                        newActiveFolders[index] = event.target.checked
                                                                                        setActiveBaseFolders(newActiveFolders)
                                                                                    }} />} label="Activo" />
                                                                        </FormGroup>
                                                                    </Grid>

                                                                    <Grid container item sm={2} xs={6} justifyContent="flex-end">
                                                                        <IconButton aria-label="eliminar carpeta base"
                                                                            onClick={() => {
                                                                                arrayHelpers.remove(index)
                                                                                const newActiveFolders = [...activeBaseFolders]
                                                                                newActiveFolders.splice(index, 1)
                                                                                const newSelectedServer = [...selectedServerFolders]
                                                                                newSelectedServer.splice(index, 1)
                                                                                setActiveBaseFolders(newActiveFolders)
                                                                                setSelectedServerFolders(newSelectedServer)
                                                                            }}
                                                                            color="error">
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                    {formik.values.base_folder.length > 1 && index !== formik.values.base_folder.length - 1 &&
                                                                        <Grid item xs={12}>
                                                                            <BoxDivider />
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            )) :
                                                        <Grid container item xs={12} justifyContent="center" >
                                                            <Typography sx={{ pt: 4, pb: 4 }} variant="h6">No ha configurado ninguna otra carpeta base para esta cuenta.</Typography>
                                                        </Grid>
                                                    }

                                                </Grid>
                                                {!isView &&
                                                    <Grid item container md={12} direction="row" spacing={2} justifyContent="center">
                                                        <Grid item>
                                                            <IconButton aria-label="agregar carpeta base"
                                                                onClick={() => {
                                                                    arrayHelpers.insert(formik.values.base_folder.length,
                                                                        { ...emptyBaseFolder })
                                                                    const newSelectedServer = [...selectedServerFolders]
                                                                    newSelectedServer.push(null)
                                                                    setSelectedServerFolders(newSelectedServer)
                                                                    const newActiveFolder = [...activeBaseFolders]
                                                                    newActiveFolder.push(false)
                                                                    setActiveBaseFolders(newActiveFolder)
                                                                }}
                                                                color="primary">
                                                                <AddCircleIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </React.Fragment>
                                        )} />
                                    <Grid item md={12} xs={12} >
                                        <TextInputMUI name="notes" label={"Notas"} required={false} isView={isView} multiline
                                            rows={8} />
                                    </Grid>
                                </Grid>
                            }
                        </CardContent>
                        {!isView &&
                            <CardActions sx={{ ml: 1, pb: 2, pr: 2 }} >
                                <Grid container direction="row" spacing={2} justifyContent="space-between"
                                    alignItems="center"  >
                                    <Grid item>
                                        <Button variant="contained" aria-label="Volver a listado" color="secondary" onClick={() => { onCancel() }} >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" aria-label={getAction()} type={"submit"} disabled={formik.isSubmitting}>
                                            {getAction()}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        }
                    </Card>

                </form>
            )
            }
        </Formik >
    )
}

export default AccountForm;