import { useState, useEffect } from 'react';

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem("token");
        const userToken = JSON.parse(tokenString)
        return userToken
    }
    const [token, setToken] = useState(getToken());

    const saveToken = (userToken) => {
        if (!userToken) {
            localStorage.clear();
        } else {
            localStorage.setItem("token", JSON.stringify(userToken));
        }
        setToken(userToken)
    }

     useEffect(() => {

        window.addEventListener('storage', () => {
            let newToken = getToken()
            saveToken(newToken)
        });


    }, []) 

    return {
        setToken: saveToken,
        token,
    }
}