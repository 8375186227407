import React, { useEffect, useState } from "react";
import {
    Grid, Typography, Box, Alert, Paper,
    Card, CardActions, CardContent, Button
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { loggedOutInstance } from 'helpers/CustomAxios';
import TextInputMUI from 'components/TextInputMUI'
import PasswordInputMUI from "components/PasswordInputMUI";
import qs from 'qs';


function Login({ setToken }) {
    const [errorMsg, setErrorMsg] = useState(null);
    return (
        <Box bgcolor="secondary.main" sx={{
            minWidth: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        }}>
            <Grid container spacing={2} alignContent="center" alignItems="center"
                justifyContent="center">
                <Grid item lg={4} md={4} sm={6} xs={10}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            username: '',
                            password: '',
                        }}
                        validationSchema={Yup.object({
                            username: Yup.string().typeError("Nombre inválido").required("Campo requerido"),
                            password: Yup.string().typeError("Contraseña inválida").required("Campo requerido"),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true);

                            loggedOutInstance.post("auth/", qs.stringify(values), {
                                headers: { 'content-type': 'application/x-www-form-urlencoded' }
                            }
                            )
                                .then(function (response) {
                                    setSubmitting(false);
                                    setToken(response.data.access_token)
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    setErrorMsg("Ha ocurrido un error");
                                    setSubmitting(false)
                                })
                        }}
                    >
                        {formik => (

                            <form onSubmit={formik.handleSubmit}>
                                <Card>

                                    <CardContent>

                                        <Grid container spacing={2} >
                                            <Grid container item xs={12} alignContent="center" alignItems="center"
                                                justifyContent="center">
                                                <Box component="div" sx={{
                                                    height: 'auto', width: 200,
                                                    backgroundColor: "white", p: 2
                                                }}>
                                                    <img src="/prisma-logo-color.svg" alt="logo" />
                                                </Box>
                                            </Grid>
                                            {errorMsg &&
                                                <Grid item xs={12}>
                                                    <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                <TextInputMUI name="username" label={"Usuario"} required={true} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PasswordInputMUI name="password" label={"Contraseña"} required={true} />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions sx={{ ml: 1, pb: 2, pr: 2 }} >
                                        <Grid container direction="row" spacing={2} justifyContent="center"
                                            alignItems="center"  >
                                            <Grid item>
                                                <LoadingButton loading={formik.isSubmitting} variant="contained" type="submit" aria-label="Ingresar" color="primary">
                                                    Ingresar
                                                </LoadingButton >
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Login;