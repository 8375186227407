import React, { useState, useEffect } from 'react';
import {
    Grid, Button, Alert, Card, CardActions, CardContent, CardHeader,
    FormGroup, FormControlLabel, Checkbox, Autocomplete, TextField, CircularProgress, Box
} from "@mui/material";
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInputMUI from 'components/TextInputMUI';
import axios from 'helpers/CustomAxios';

function OutputFileForm({ onSuccess = null, selectedId, isView = false, isEdit = false,
    isCreate = false, onCancel = null, accountId = null }) {
    const [defaultOptions, setDefaultOptions] = useState(null)
    const [active, setActive] = useState(false);
    const [encrypt, setEncrypt] = useState(false);
    const [sendByEmail, setSendByEmail] = useState(false);
    const [extraFolders, setExtraFolders] = useState(false);

    const [errorMsg, setErrorMsg] = useState(null);
    const [initialData, setInitialData] = useState(null);
    const [loadedOptions, setLoadedOptions] = useState(false);
    const [fullyLoaded, setFullyLoaded] = useState(false);


    const [designOptions, setDesignOptions] = useState([]);
    const [selectedDesign, setSelectedDesign] = useState([]);
    const [dateFormatOptions, setDateFormatOptions] = useState([]);
    const [selectedDateFormat, setSelectedDateFormat] = useState(null);

    const [emailGroupsOptions, setEmailGroupsOptions] = useState([]);
    const [selectedEmailGroup, setSelectedEmailGroup] = useState(null);

    const [baseFolderOptions, setBaseFolderOptions] = useState([]);
    const [selectedBaseFolder, setSelectedBaseFolder] = useState(null);
    const [accountFolderOptions, setAccountFolderOptions] = useState([]);
    const [selectedAccountFolder, setSelectedAccountFolder] = useState(null);

    useEffect(() => {
        setFullyLoaded(false)
        //Carga las opciones de los select primero
        Promise.all([
            axios.get("accountfolders/combo/" + accountId,)
                .then(function (response) {
                    setAccountFolderOptions(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }),
            axios.get("accounts/" + accountId, {})
                .then(function (response) {
                    const groups = response.data.email_groups.map(i => {
                        return { value: i.id, label: i.name }
                    })
                    setEmailGroupsOptions(groups)
                    let formattedBaseFolder = []
                    response.data.base_folder.forEach(baseFolder => {
                        if (baseFolder.is_active) {
                            let bf = {
                                label: baseFolder.base_folder,
                                value: baseFolder.id,
                                accountFolders: []
                            }
                            baseFolder.account_folders.forEach(folder => {
                                if (folder.is_active && !folder.reading_folder) {
                                    bf.accountFolders.push({
                                        label: folder.work_folder,
                                        value: folder.id
                                    })
                                }
                            });
                            formattedBaseFolder.push(bf)
                        }
                    });
                    setBaseFolderOptions(formattedBaseFolder)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }),
            axios.get("utils/", { params: { table: "outputdesigns", column: "name" } })
                .then(function (response) {
                    setDesignOptions(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }),
            axios.get("utils/", { params: { table: "dateformats", column: "name" } })
                .then(function (response) {
                    setDateFormatOptions(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }),

        ])
            .then(function () {
                setLoadedOptions(true)
            })

    }, [])

    useEffect(() => {
        if (selectedBaseFolder) {
            setAccountFolderOptions(selectedBaseFolder.accountFolders)
        } else {
            setAccountFolderOptions([])
        }
    }, [selectedBaseFolder])

    useEffect(() => {
        if (loadedOptions && selectedId) {
            axios.get("accountoutputfiles/" + selectedId, {})
                .then(function (response) {
                    setInitialData(response.data)
                    setEncrypt(response.data.encrypt)
                    setSendByEmail(response.data.send_by_email)
                    setExtraFolders(response.data.extra_folders)
                    setActive(response.data.is_active)

                    setSelectedBaseFolder(baseFolderOptions.find((i) => i.value === response.data.account_base_folder_id))
                    setSelectedAccountFolder(accountFolderOptions.find((i) => i.value === response.data.account_folder_id))
                    setSelectedDesign(designOptions.find((i) => i.value === response.data.output_design_id))
                    setSelectedDateFormat(dateFormatOptions.find((i) => i.value === response.data.output_dateformat_id))
                    setSelectedEmailGroup(emailGroupsOptions.find((i) => i.value === response.data.emailgroups_id))
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                })
                .then(() => {
                    setFullyLoaded(true)
                })
        }
        if (!selectedId && loadedOptions) {
            const selAccountFolder = accountFolderOptions.find((i) => i.label === "download");
            setSelectedAccountFolder(selAccountFolder)
            let init = { account_folder_id: selAccountFolder.value }
            setDefaultOptions(init)

            setFullyLoaded(true)
        }
    }, [loadedOptions])

    const getAction = () => {
        if (isCreate) {
            return "Crear"
        }
        else if (isEdit) {
            return "Editar"
        }
        else if (isView) {
            return "Ver"
        }
    }

    return (
        <>
            {!fullyLoaded ?
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, height: "400px", alignItems: "center", }}>
                    <CircularProgress />
                </Box>
                :
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        account_id: accountId,
                        original_file: initialData ? initialData.original_file : '',
                        output_filebase: initialData ? initialData.output_filebase : '',
                        output_extension: initialData ? initialData.output_extension : null,

                        encrypt: initialData ? initialData.encrypt : false,
                        send_by_email: initialData ? initialData.send_by_email : false,
                        extra_folders: initialData ? initialData.extra_folders : false,
                        account_base_folder_id: initialData ? initialData.account_base_folder_id : null,
                        account_folder_id: initialData ? initialData.account_folder_id : defaultOptions?.account_folder_id,
                        output_design_id: initialData ? initialData.output_design_id : null,
                        output_dateformat_id: initialData ? initialData.output_dateformat_id : null,
                        emailgroups_id: initialData ? initialData.emailgroups_id : null,
                        is_active: initialData ? initialData.is_active : false

                    }}
                    validationSchema={Yup.object({
                        original_file: Yup.string().typeError("Campo inválido").required("Campo requerido"),
                        output_filebase: Yup.string().typeError("Campo inválido").required("Campo requerido"),
                        output_extension: Yup.string().typeError("Campo inválido").nullable(),
                        encrypt: Yup.boolean().typeError("Campo inválido"),
                        send_by_email: Yup.boolean().typeError("Campo inválido"),
                        extra_folders: Yup.boolean().typeError("Campo inválido"),
                        is_active: Yup.boolean().typeError("Campo inválido"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        let requestData = {...values}
                        if(requestData.output_extension === "") requestData.output_extension = null
                        if (isCreate) {
                            axios.post("accountoutputfiles/", requestData)
                                .then(function (response) {
                                    onSuccess(requestData.output_filebase + " se ha creado correctamente.")
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    setErrorMsg("Ha ocurrido un error");
                                    setSubmitting(false);
                                })
                        } else if (isEdit) {
                            axios.put("accountoutputfiles/" + selectedId, requestData)
                                .then(function (response) {
                                    onSuccess(requestData.output_filebase + " se ha editado correctamente.")
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    setErrorMsg("Ha ocurrido un error");
                                    setSubmitting(false);
                                })
                        }
                    }}
                >
                    {formik => (

                        <form onSubmit={formik.handleSubmit}>

                            <Card >
                                <CardHeader title={getAction() + " Output File"} />
                                <CardContent>
                                    <Grid container spacing={2} alignContent="center">
                                        {errorMsg &&
                                            <Grid item xs={12}>
                                                <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                                            </Grid>
                                        }
                                        <Grid item container md={12} direction="row" spacing={2}>
                                            <Grid item md={3} sm={4} xs={12}>
                                                <TextInputMUI name="original_file" label={"Original File"} required={true} isView={isView} />
                                            </Grid>
                                            <Grid item md={3} sm={4} xs={12}>
                                                <TextInputMUI name="output_filebase" label={"File Base"} required={true} isView={isView} />
                                            </Grid>

                                            <Grid item md={3} sm={4} xs={12}>
                                                <TextInputMUI name="output_extension" label={"Extension"}  isView={isView} />
                                            </Grid>
                                            <Grid item md={3} sm={4} xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel disabled={isView} control={<Checkbox checked={active}
                                                        onChange={(event) => {
                                                            formik.setFieldValue("is_active", event.target.checked)
                                                            setActive(event.target.checked)
                                                        }} />} label="Activo" />
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container md={12} direction="row" spacing={2}>
                                            <Grid item md={4} sm={4} xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel disabled={isView} control={<Checkbox checked={encrypt}
                                                        onChange={(event) => {
                                                            formik.setFieldValue("encrypt", event.target.checked)
                                                            setEncrypt(event.target.checked)
                                                        }} />} label="Encriptar" />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={4} sm={4} xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel disabled={isView} control={<Checkbox checked={sendByEmail}
                                                        onChange={(event) => {
                                                            formik.setFieldValue("send_by_email", event.target.checked)
                                                            setSendByEmail(event.target.checked)
                                                            if (!event.target.checked) {
                                                                setSelectedEmailGroup(null)
                                                                formik.setFieldValue("emailgroups_id", null)
                                                            }
                                                        }} />} label="Enviar por E-Mail" />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={4} sm={4} xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel disabled={isView} control={<Checkbox checked={extraFolders}
                                                        onChange={(event) => {
                                                            formik.setFieldValue("extra_folders", event.target.checked)
                                                            setExtraFolders(event.target.checked)
                                                        }} />} label="Duplica Archivos" />
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container md={12} direction="row" spacing={2}>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <Autocomplete
                                                    disablePortal getOptionLabel={(option) => (option.label ? option.label : "")}
                                                    value={selectedBaseFolder}
                                                    onChange={(event, newValue) => {
                                                        setSelectedBaseFolder(newValue)
                                                        setSelectedAccountFolder(null)

                                                        formik.setFieldValue("account_base_folder_id", newValue?.value)
                                                        formik.setFieldValue("account_folder_id", null)
                                                    }}
                                                    required
                                                    disabled={isView}
                                                    id="combo-account-base-folder"
                                                    options={baseFolderOptions}
                                                    disableClearable
                                                    renderInput={(params) => <TextField {...params} required label="Carpeta Base" />}
                                                />
                                            </Grid>
                                            {selectedBaseFolder && accountFolderOptions.length > 0 &&
                                                <Grid item md={4} sm={6} xs={12}>
                                                    <Autocomplete
                                                        disablePortal getOptionLabel={(option) => (option.label ? option.label : "")}
                                                        value={selectedAccountFolder}
                                                        onChange={(event, newValue) => {
                                                            setSelectedAccountFolder(newValue)
                                                            formik.setFieldValue("account_folder_id", newValue?.value)
                                                        }}
                                                        disabled={isView}
                                                        id="combo-account-folder"
                                                        options={accountFolderOptions}
                                                        required
                                                        disableClearable
                                                        renderInput={(params) => <TextField {...params} required label="Carpeta Interna" />}
                                                    />
                                                </Grid>
                                            }
                                            <Grid item md={4} sm={6} xs={12}>
                                                <Autocomplete
                                                    disablePortal getOptionLabel={(option) => (option.label ? option.label : "")}
                                                    value={selectedDesign}
                                                    onChange={(event, newValue) => {
                                                        setSelectedDesign(newValue)
                                                        formik.setFieldValue("output_design_id", newValue?.value)
                                                    }}
                                                    disabled={isView}
                                                    id="combo-design"
                                                    options={designOptions}
                                                    renderInput={(params) => <TextField {...params} label="Diseño" />}
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <Autocomplete
                                                    disablePortal getOptionLabel={(option) => (option.label ? option.label : "")}
                                                    value={selectedDateFormat}
                                                    onChange={(event, newValue) => {
                                                        setSelectedDateFormat(newValue)
                                                        formik.setFieldValue("output_dateformat_id", newValue?.value)
                                                    }}
                                                    disabled={isView}
                                                    id="combo-dateformat"
                                                    options={dateFormatOptions}
                                                    renderInput={(params) => <TextField {...params} label="Output Date Format" />}
                                                />
                                            </Grid>
                                            {sendByEmail &&
                                                <Grid item md={4} sm={6} xs={12}>
                                                    <Autocomplete
                                                        disablePortal getOptionLabel={(option) => (option.label ? option.label : "")}
                                                        value={selectedEmailGroup}
                                                        onChange={(event, newValue) => {
                                                            setSelectedEmailGroup(newValue)
                                                            formik.setFieldValue("emailgroups_id", newValue?.value)
                                                        }}
                                                        disabled={isView}
                                                        id="combo-email-groups"
                                                        options={emailGroupsOptions}
                                                        renderInput={(params) => <TextField {...params} label="Grupo de E-Mail" />}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>

                                    </Grid>
                                </CardContent>
                                <CardActions sx={{ ml: 1, pb: 2, pr: 2 }} >
                                    <Grid container direction="row" spacing={2} justifyContent="space-between"
                                        alignItems="center"  >
                                        <Grid item>
                                            <Button variant="contained" aria-label="Volver a listado" color="secondary" onClick={() => { onCancel() }} disabled={false}>
                                                Volver a Listado
                                            </Button>
                                        </Grid>
                                        {!isView &&
                                            <Grid item>
                                                <Button variant="contained" aria-label={getAction()} type={"submit"} disabled={formik.isSubmitting}>
                                                    {getAction()}
                                                </Button>
                                            </Grid>
                                        }
                                    </Grid>
                                </CardActions>
                            </Card>

                        </form>
                    )}
                </Formik>
            }
        </>
    )
}

export default OutputFileForm;