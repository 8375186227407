import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Alert, FormControlLabel } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { GridLocalization } from 'helpers/general';
import Switch from '@mui/material/Switch';
import axios from 'helpers/CustomAxios';
import CustomPagination from 'components/CustomPagination';
function ParameterModuleAdministration() {
    const [data, setData] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [page, setPage] = useState(0);
    const [loadingData, setLoadingData] = useState(true);

    const [errorMsg, setErrorMsg] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filterActive, setFilterActive] = useState(true);


    useEffect(() => {
        loadData();
    }, [pageSize, page, filterActive])

    const columns = [
        { field: 'name', headerName: 'Nombre', flex: 2, sortable: false },
        {
            field: 'is_active', headerName: 'Activo', flex: 1, sortable: false,
            renderCell: (params) => {
                return (
                    <Box component="div" justifyContent="center" width="100%" >
                        {params.row.is_active ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon color="disabled" />}
                    </Box>
                )
            }
        },
    ];

    const loadData = () => {
        setLoadingData(true);
        axios.get("parametersmodules/", { params: { page: page + 1, size: pageSize, is_active: filterActive } })
            .then(function (response) {
                setData(response.data.items);
                setTotalRecords(response.data.total)
            })
            .catch(function (error) {
                console.log(error);
                setErrorMsg("Ha ocurrido un error");
            })
            .then(function () {
                setLoadingData(false)
            })
    }


    return (

        <Grid container spacing={2}>
            <Grid item xs={12} container spacing={2} direction="row" justifyContent={{ xs: "center", sm: "space-between" }} alignItems="center">
                <Grid item md={9} sm={8} xs={12}>
                    <Typography variant="h4">Módulos de Parámetro</Typography>
                </Grid>
                {errorMsg &&
                    <Grid item xs={12}>
                        <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                    </Grid>
                }
            </Grid>
            <Grid item xs={12} container spacing={2} direction="row" justifyContent={{ xs: "center", sm: "space-between" }} alignItems="center">
                <Grid item md={3} sm={4} xs={12}>
                    <FormControlLabel
                        control={<Switch
                            checked={filterActive}
                            onChange={(event) => { setFilterActive(event.target.checked) }}
                            inputProps={{ 'aria-label': 'filtrar activas' }}
                        />
                        } label={filterActive ? "Mostrando Activos" : "Mostrando Inactivos"} />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <div style={{ height: 450, width: '100%' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                localeText={GridLocalization}
                                disableColumnFilter
                                density="compact"
                                pagination
                                paginationMode="server"
                                components={{
                                    Pagination: CustomPagination,
                                }}
                                rowCount={totalRecords}
                                loading={loadingData}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                page={page}
                                onPageChange={(newPage) => setPage(newPage)}
                                rows={data}
                                columns={columns} />
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>

    )
}

export default ParameterModuleAdministration;