import React from 'react';
import { Box } from "@mui/material";
function BoxDivider({ width = "100%", color = "secondary.light", height = "1px" }) {
    return (
        <Box
            sx={{
                width: width,
                height: height,
                backgroundColor: color,
            }} />
    )
}
export default BoxDivider;
