import React from 'react';
import { Box, Tooltip } from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
function GridCheckbox({ isActive, hasTooltip = false, tooltipText = "", tooltipPlacement = "top" }) {
    return (
        <>
            {
                hasTooltip ?
                    <Tooltip title={tooltipText} placement={tooltipPlacement} arrow >
                        <div>
                            <Box component="div" justifyContent="center" width="100%" >
                                {isActive ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon color="disabled" />}
                            </Box>
                        </div>
                    </Tooltip >
                    :
                    <Box component="div" justifyContent="center" width="100%" >
                        {isActive ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon color="disabled" />}
                    </Box>
            }
        </>
    )
}
export default GridCheckbox;
