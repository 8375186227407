import React from 'react'
import { useField } from 'formik'
import { TextField, Stack, InputAdornment, IconButton } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { format } from 'date-fns'
import ClearIcon from '@mui/icons-material/Clear';

function DatePickerFormik({ isView = false, inputValue, onChangeInputValue, datepickerprops = {},
    required = false, dateformat = "dd/MM/yyyy", ...props }) {
    const [field, meta, helpers] = useField(props)
    const { setValue } = helpers

    return (
        <DatePicker
            value={inputValue}
            onChange={(newValue) => {
                onChangeInputValue(newValue)
                setValue(format(newValue, dateformat))
            }}
            inputValue={inputValue}
            views={['year', 'month', 'day']}
            {...datepickerprops}
            disabled={isView}
            renderInput={({ inputRef, inputProps, InputProps }) => {
                inputProps.readOnly = true
                return (
                    <Stack direction="row" spacing={1} sx={{  alignItems: "center" }}>
                       
                        <TextField
                            ref={inputRef}
                            variant="outlined"
                            inputProps={inputProps}
                            InputLabelProps={{ shrink: true }}
                            disabled={isView}
                            fullWidth
                            {...field} {...props}
                            required={required}
                            error={meta.touched && Boolean(meta.error)}
                            helperText={meta.error && meta.touched ? meta.error : null}
                            InputProps={(inputValue && !required) ?
                                {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="limpiar fecha"
                                                onClick={() => {
                                                    onChangeInputValue(null)
                                                    setValue(null)
                                                }}
                                                onMouseDown={(e) => { e.preventDefault() }}
                                                edge="end"
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                } :  null
                            }
                        />
                         {InputProps?.endAdornment}
                    </Stack>
                )

            }}
        />
    )
}

export default DatePickerFormik