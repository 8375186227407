import React, { useState } from 'react';
import {
    Pagination, IconButton, TextField, InputAdornment,
    FormControl, Select, InputLabel, Grid
} from "@mui/material"
import { MenuItem } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
    gridPageCountSelector,
    gridPageSelector,
    gridPageSizeSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const [inputPage, setInputPage] = useState(page + 1)
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

    const theme = useTheme();
    const biggerThanMd = useMediaQuery(theme.breakpoints.up('md'));

    const handleChangeRowsPerPage = (event) => {
        apiRef.current.setPageSize(parseInt(event.target.value, 10))
        apiRef.current.setPage(0);
    };

    return (
        <Grid item container md={12} direction="row" spacing={2}
            justifyContent="space-between" sx={{ pb: 2, pt: 3, px: 2 }}>
            <Grid item md={2} sm={3} xs={12}>
                <FormControl fullWidth size="small">
                    <InputLabel>Mostrar</InputLabel>
                    <Select

                        value={pageSize}
                        label="Mostrar"
                        onChange={handleChangeRowsPerPage}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={8} sm={6} xs={12} sx={{ margin: "auto" }}>
                {pageCount > 0 &&
                    <Pagination
                        color="primary"
                        size={biggerThanMd ? "medium" : "small"}
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => apiRef.current.setPage(value - 1)}
                    />
                }
            </Grid>
            <Grid item md={2} sm={3} xs={12}>
                <TextField
                    fullWidth
                    value={inputPage}
                    variant="outlined"
                    size="small"
                    type="number"
                    onChange={(e) => {
                        if (!e.target.value || parseInt(e.target.value) === NaN) {
                            setInputPage(1)
                        } else {
                            setInputPage(e.target.value)
                        }
                    }}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="ir a página"
                                    onClick={() => apiRef.current.setPage(inputPage - 1)}
                                    edge="end"
                                >
                                    <ChevronRightIcon />
                                </IconButton>
                            </InputAdornment>,
                    }}
                    label="Ir a"
                    inputProps={{
                        'aria-label': 'ingresar página',
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default CustomPagination;