import React, { useState, useEffect } from 'react';
import {
    Grid, Button, Alert, Card, CardActions, CardContent, CardHeader,
    FormGroup, FormControlLabel, Checkbox, Autocomplete, TextField, CircularProgress, Box
} from "@mui/material";
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInputMUI from 'components/TextInputMUI';
import axios from 'helpers/CustomAxios';

function FileInputForm({ onSuccess = null, selectedId, isView = false, isEdit = false,
    isCreate = false, onCancel = null, accountId = null }) {

    const [defaultOptions, setDefaultOptions] = useState(null)
    const [active, setActive] = useState(false);
    const [encrypt, setEncrypt] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [compress, setCompress] = useState(false);
    const [padding, setPadding] = useState(false);
    const [notifyDelays, setNotifyDelays] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [initialData, setInitialData] = useState(null);
    const [loadedOptions, setLoadedOptions] = useState(false);
    const [fullyLoaded, setFullyLoaded] = useState(false);

    const [accountFolderOptions, setAccountFolderOptions] = useState([]);
    const [selectedAccountFolder, setSelectedAccountFolder] = useState(null);
    const [designOptions, setDesignOptions] = useState([]);
    const [selectedDesign, setSelectedDesign] = useState([]);
    const [validationGroupOptions, setValidationGroupOptions] = useState([]);
    const [selectedValidationGroup, setSelectedValidationGroup] = useState(null);
    const [fileProcessTypeOptions, setFileProcessTypeOptions] = useState([]);
    const [selectedFileProcessType, setSelectedFileProcessType] = useState(null);
    const [fileDataInfoOptions, setFileDataInfoOptions] = useState([]);
    const [selectedFileDataInfo, setSelectedFileDataInfo] = useState([]);
    const [fileMaskOptions, setFileMaskOptions] = useState([]);
    const [selectedFileMask, setSelectedFileMask] = useState(null);
    const [fileSequenceOptions, setFileSequenceOptions] = useState([]);
    const [selectedFileSequence, setSelectedFileSequence] = useState(null);
    const [fileScheduleOptions, setFileScheduleOptions] = useState([]);
    const [selectedFileSchedule, setSelectedFileSchedule] = useState(null);
    const [encodingOptions, setEncodingOptions] = useState([]);
    const [selectedEncoding, setSelectedEncoding] = useState(null);
    const [headingOptions, setHeadingOptions] = useState([]);
    const [selectedHeading, setSelectedHeading] = useState(null);
    const [emailGroupsOptions, setEmailGroupsOptions] = useState([]);
    const [selectedEmailGroup, setSelectedEmailGroup] = useState(null);
    useEffect(() => {
        setFullyLoaded(false)
        //Carga las opciones de los select primero
        Promise.all([           
            axios.get("accounts/" + accountId, {})
                .then(function (response) {
                    const groups = response.data.email_groups.map(i => {
                        return { value: i.id, label: i.name }
                    })
                    setEmailGroupsOptions(groups)

                    let formattedFolders = []
                    response.data.base_folder.forEach(baseFolder => {
                        if (baseFolder.is_active) {                          
                            baseFolder.account_folders.forEach(folder => {
                                if (folder.is_active && folder.reading_folder) {
                                    formattedFolders.push({
                                        label: baseFolder.base_folder + "/"+ folder.work_folder,
                                        value: folder.id
                                    })
                                }
                            });
                        }
                    });
                    setAccountFolderOptions(formattedFolders)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }),
            axios.get("utils/", { params: { table: "designs", column: "name" } })
                .then(function (response) {
                    setDesignOptions(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }),
            axios.get("utils/", { params: { table: "validationgroups", column: "name" } })
                .then(function (response) {
                    setValidationGroupOptions(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }),
            axios.get("utils/", { params: { table: "fileprocesstypes", column: "name" } })
                .then(function (response) {
                    setFileProcessTypeOptions(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }),
            axios.get("utils/", { params: { table: "filemasks", column: "name" } })
                .then(function (response) {
                    setFileMaskOptions(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }),
            axios.get("utils/", { params: { table: "filedatainfo", column: "name" } })
                .then(function (response) {
                    setFileDataInfoOptions(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }),
            axios.get("utils/", { params: { table: "filesequences", column: "name" } })
                .then(function (response) {
                    setFileSequenceOptions(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }),
            axios.get("utils/", { params: { table: "fileschedule", column: "name" } })
                .then(function (response) {
                    setFileScheduleOptions(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }),
            axios.get("utils/", { params: { table: "headings", column: "name" } })
                .then(function (response) {
                    setHeadingOptions(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }),
            axios.get("utils/", { params: { table: "fileencodings", column: "name" } })
                .then(function (response) {
                    setEncodingOptions(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }),
        ])
            .then(function () {
                setLoadedOptions(true)
            })

    }, [])

    useEffect(() => {
        if (loadedOptions && selectedId) {
            axios.get("accountinputfiles/" + selectedId, {})
                .then(function (response) {
                    setInitialData(response.data)
                    setActive(response.data.is_active)
                    setEncrypt(response.data.encrypt)
                    setCompress(response.data.compress)
                    setPadding(response.data.padding)
                    setNotifyDelays(response.data.notify_delays)
                    setSendEmail(response.data.send_email)

                    setSelectedAccountFolder(accountFolderOptions.find((i) => i.value === response.data.account_folder_id))
                    setSelectedDesign(designOptions.find((i) => i.value === response.data.design_id))
                    setSelectedValidationGroup(validationGroupOptions.find((i) => i.value === response.data.validationgroup_id))
                    setSelectedFileProcessType(fileProcessTypeOptions.find((i) => i.value === response.data.file_process_type_id))
                    setSelectedFileMask(fileMaskOptions.find((i) => i.value === response.data.filemask_id))
                    setSelectedFileDataInfo(fileDataInfoOptions.find((i) => i.value === response.data.filedatainfo_id))
                    setSelectedFileSequence(fileSequenceOptions.find((i) => i.value === response.data.file_sequence_id))
                    setSelectedFileSchedule(fileScheduleOptions.find((i) => i.value === response.data.file_schedule_id))
                    setSelectedHeading(headingOptions.find((i) => i.value === response.data.heading_id))
                    setSelectedEncoding(encodingOptions.find((i) => i.value === response.data.encoding_id))
                    setSelectedEmailGroup(emailGroupsOptions.find((i) => i.value === response.data.emailgroups_id))
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                })
                .then(() => {
                    setFullyLoaded(true)
                })
        }
        if (!selectedId && loadedOptions) {
            const selAccountFolder = accountFolderOptions.find((i) => i.label.includes("upload"));
            setSelectedAccountFolder(selAccountFolder)
            let init = { account_folder_id: selAccountFolder.value }
            setDefaultOptions(init)
            setFullyLoaded(true)
        }
    }, [loadedOptions])

    const getAction = () => {
        if (isCreate) {
            return "Crear"
        }
        else if (isEdit) {
            return "Editar"
        }
        else if (isView) {
            return "Ver"
        }
    }

    return (
        <>
            {!fullyLoaded ?
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, height: "400px", alignItems: "center", }}>
                    <CircularProgress />
                </Box>
                :
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        file_fiid: initialData ? initialData.file_fiid : '',
                        file_refresh_id: initialData ? initialData.file_refresh_id : '',
                        file_basename: initialData ? initialData.file_basename : '',
                        record_len: initialData ? initialData.record_len : 0,
                        encrypt: initialData ? initialData.encrypt : false,
                        compress: initialData ? initialData.compress : false,
                        padding: initialData ? initialData.padding : false,
                        notify_delays: initialData ? initialData.notify_delays : false,
                        waiting_delay: initialData ? initialData.waiting_delay : 0,
                        account_folder_id: initialData ? initialData.account_folder_id : defaultOptions?.account_folder_id,
                        design_id: initialData ? initialData.design_id : null,
                        validationgroup_id: initialData ? initialData.validationgroup_id : null,
                        file_process_type_id: initialData ? initialData.file_process_type_id : null,
                        filedatainfo_id: initialData ? initialData.filedatainfo_id : null,
                        filemask_id: initialData ? initialData.filemask_id : null,
                        file_sequence_id: initialData ? initialData.file_sequence_id : null,
                        file_schedule_id: initialData ? initialData.file_schedule_id : null,
                        heading_id: initialData ? initialData.heading_id : null,
                        encoding_id: initialData ? initialData.encoding_id : null,
                        emailgroups_id: initialData ? initialData.emailgroups_id : null,
                        send_email: initialData ? initialData.send_email : false,
                        is_active: initialData ? initialData.is_active : false
                    }}
                    validationSchema={Yup.object({
                        file_fiid: Yup.string().typeError("Campo inválido").required("Campo requerido"),
                        file_refresh_id: Yup.string().typeError("Campo inválido").required("Campo requerido"),
                        file_basename: Yup.string().typeError("Campo inválido").required("Campo requerido"),
                        record_len: Yup.number().typeError("Ingrese un número entero").integer("Ingrese un número entero").min(0, "Ingrese un número igual o mayor a 0").required("Campo requerido"),
                        encrypt: Yup.boolean().typeError("Campo inválido"),
                        compress: Yup.boolean().typeError("Campo inválido"),
                        padding: Yup.boolean().typeError("Campo inválido"),
                        notify_delays: Yup.boolean().typeError("Campo inválido"),
                        waiting_delay: Yup.number().typeError("Ingrese un número entero").integer("Ingrese un número entero").min(0, "Ingrese un número igual o mayor a 0").required("Campo requerido"),

                        is_active: Yup.boolean().typeError("Campo inválido"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        values.account_id = accountId;
                        if (isCreate) {
                            axios.post("accountinputfiles/", values)
                                .then(function (response) {
                                    onSuccess(values.file_basename + " se ha creado correctamente.")
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    setErrorMsg("Ha ocurrido un error");
                                    setSubmitting(false);
                                })
                        } else if (isEdit) {
                            axios.put("accountinputfiles/" + selectedId, values)
                                .then(function (response) {
                                    onSuccess(values.file_basename + " se ha editado correctamente.")
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    setErrorMsg("Ha ocurrido un error");
                                    setSubmitting(false);
                                })
                        }
                    }}
                >
                    {formik => (

                        <form onSubmit={formik.handleSubmit}>

                            <Card >
                                <CardHeader title={getAction() + " Input File"} />
                                <CardContent>
                                    <Grid container spacing={2} alignContent="center">
                                        {errorMsg &&
                                            <Grid item xs={12}>
                                                <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                                            </Grid>
                                        }
                                        <Grid item container md={12} direction="row" spacing={2}>
                                            <Grid item md={4} sm={4} xs={12}>
                                                <TextInputMUI name="file_basename" label={"Nombre Base"} required={true} isView={isView} />
                                            </Grid>
                                            <Grid item md={4} sm={4} xs={12}>
                                                <TextInputMUI name="file_fiid" label={"Fiid"} required={true} isView={isView} />
                                            </Grid>
                                            <Grid item md={4} sm={4} xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel disabled={isView} control={<Checkbox checked={active}
                                                        onChange={(event) => {
                                                            formik.setFieldValue("is_active", event.target.checked)
                                                            setActive(event.target.checked)
                                                        }} />} label="Activo" />
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container md={12} direction="row" spacing={2}>
                                            <Grid item md={4} sm={4} xs={12}>
                                                <TextInputMUI name="file_refresh_id" label={"File Refresh"} required={true} isView={isView} />
                                            </Grid>
                                            <Grid item md={4} sm={4} xs={12}>
                                                <TextInputMUI name="record_len" label={"Record_len"} type="number" required={true} isView={isView} />
                                            </Grid>
                                        </Grid>
                                        <Grid item container md={12} direction="row" spacing={2}>
                                            <Grid item md={2} sm={4} xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel disabled={isView} control={<Checkbox checked={encrypt}
                                                        onChange={(event) => {
                                                            formik.setFieldValue("encrypt", event.target.checked)
                                                            setEncrypt(event.target.checked)
                                                        }} />} label="Encriptar" />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={2} sm={4} xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel disabled={isView} control={<Checkbox checked={compress}
                                                        onChange={(event) => {
                                                            formik.setFieldValue("compress", event.target.checked)
                                                            setCompress(event.target.checked)
                                                        }} />} label="Comprimir" />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={2} sm={4} xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel disabled={isView} control={<Checkbox checked={padding}
                                                        onChange={(event) => {
                                                            formik.setFieldValue("padding", event.target.checked)
                                                            setPadding(event.target.checked)
                                                        }} />} label="Padding" />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={2} sm={4} xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel disabled={isView} control={<Checkbox checked={notifyDelays}
                                                        onChange={(event) => {
                                                            formik.setFieldValue("notify_delays", event.target.checked)
                                                            setNotifyDelays(event.target.checked)
                                                        }} />} label="Notificar Retrasos" />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={4} sm={8} xs={12}>
                                                <TextInputMUI name="waiting_delay" type="number" label={"Tiempo de Espera Notificación"} required={true} isView={isView} />
                                            </Grid>
                                        </Grid>
                                        <Grid item container md={12} direction="row" spacing={2}>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <Autocomplete
                                                    disablePortal getOptionLabel={(option) => (option.label ? option.label : "")}
                                                    value={selectedAccountFolder}
                                                    onChange={(event, newValue) => {
                                                        setSelectedAccountFolder(newValue)
                                                        formik.setFieldValue("account_folder_id", newValue?.value)
                                                    }}
                                                    disabled={isView}
                                                    id="combo-accountfolder"
                                                    options={accountFolderOptions}
                                                    renderInput={(params) => <TextField {...params} label="Carpeta" />}
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <Autocomplete
                                                    disablePortal getOptionLabel={(option) => (option.label ? option.label : "")}
                                                    value={selectedDesign}
                                                    onChange={(event, newValue) => {
                                                        setSelectedDesign(newValue)
                                                        formik.setFieldValue("design_id", newValue?.value)
                                                    }}
                                                    disabled={isView}
                                                    id="combo-design"
                                                    options={designOptions}
                                                    renderInput={(params) => <TextField {...params} label="Diseño" />}
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <Autocomplete
                                                    disablePortal getOptionLabel={(option) => (option.label ? option.label : "")}
                                                    value={selectedValidationGroup}
                                                    onChange={(event, newValue) => {
                                                        setSelectedValidationGroup(newValue)
                                                        formik.setFieldValue("validationgroup_id", newValue?.value)
                                                    }}
                                                    disabled={isView}
                                                    id="combo-validationgroup"
                                                    options={validationGroupOptions}
                                                    renderInput={(params) => <TextField {...params} label="Grupo de Validación" />}
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <Autocomplete
                                                    disablePortal getOptionLabel={(option) => (option.label ? option.label : "")}
                                                    value={selectedHeading}
                                                    onChange={(event, newValue) => {
                                                        setSelectedHeading(newValue)
                                                        formik.setFieldValue("heading_id", newValue?.value)
                                                    }}
                                                    disabled={isView}
                                                    id="combo-heading"
                                                    options={headingOptions}
                                                    renderInput={(params) => <TextField {...params} label="Rubro" />}
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <Autocomplete
                                                    disablePortal getOptionLabel={(option) => (option.label ? option.label : "")}
                                                    value={selectedEncoding}
                                                    onChange={(event, newValue) => {
                                                        setSelectedEncoding(newValue)
                                                        formik.setFieldValue("encoding_id", newValue?.value)
                                                    }}
                                                    disabled={isView}
                                                    id="combo-encoding"
                                                    options={encodingOptions}
                                                    renderInput={(params) => <TextField {...params} label="Encoding" />}
                                                />
                                            </Grid>

                                        </Grid>
                                        <Grid item container md={12} direction="row" spacing={2}>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <Autocomplete
                                                    disablePortal getOptionLabel={(option) => (option.label ? option.label : "")}
                                                    value={selectedFileProcessType}
                                                    onChange={(event, newValue) => {
                                                        setSelectedFileProcessType(newValue)
                                                        formik.setFieldValue("file_process_type_id", newValue?.value)
                                                    }}
                                                    disabled={isView}
                                                    id="combo-fileprocesstype"
                                                    options={fileProcessTypeOptions}
                                                    renderInput={(params) => <TextField {...params} label="File Process Type" />}
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <Autocomplete
                                                    disablePortal getOptionLabel={(option) => (option.label ? option.label : "")}
                                                    value={selectedFileMask}
                                                    onChange={(event, newValue) => {
                                                        setSelectedFileMask(newValue)
                                                        formik.setFieldValue("filemask_id", newValue?.value)
                                                    }}
                                                    disabled={isView}
                                                    id="combo-filemask"
                                                    options={fileMaskOptions}
                                                    renderInput={(params) => <TextField {...params} label="File Mask" />}
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <Autocomplete
                                                    disablePortal getOptionLabel={(option) => (option.label ? option.label : "")}
                                                    value={selectedFileDataInfo}
                                                    onChange={(event, newValue) => {
                                                        setSelectedFileDataInfo(newValue)
                                                        formik.setFieldValue("filedatainfo_id", newValue?.value)
                                                    }}
                                                    disabled={isView}
                                                    id="combo-filedatainfo"
                                                    options={fileDataInfoOptions}
                                                    renderInput={(params) => <TextField {...params} label="File Data Info" />}
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <Autocomplete
                                                    disablePortal getOptionLabel={(option) => (option.label ? option.label : "")}
                                                    value={selectedFileSequence}
                                                    onChange={(event, newValue) => {
                                                        setSelectedFileSequence(newValue)
                                                        formik.setFieldValue("file_sequence_id", newValue?.value)
                                                    }}
                                                    disabled={isView}
                                                    id="combo-filesequence"
                                                    options={fileSequenceOptions}
                                                    renderInput={(params) => <TextField {...params} label="File Sequence" />}
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <Autocomplete
                                                    disablePortal getOptionLabel={(option) => (option.label ? option.label : "")}
                                                    value={selectedFileSchedule}
                                                    onChange={(event, newValue) => {
                                                        setSelectedFileSchedule(newValue)
                                                        formik.setFieldValue("file_schedule_id", newValue?.value)
                                                    }}
                                                    disabled={isView}
                                                    id="combo-fileschedule"
                                                    options={fileScheduleOptions}
                                                    renderInput={(params) => <TextField {...params} label="File Schedule" />}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item container md={12} direction="row" spacing={2}>
                                            <Grid item md={2} sm={4} xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel disabled={isView} control={<Checkbox checked={sendEmail}
                                                        onChange={(event) => {
                                                            formik.setFieldValue("send_email", event.target.checked)
                                                            setSendEmail(event.target.checked)
                                                            if(!event.target.checked){
                                                                setSelectedEmailGroup(null)
                                                                formik.setFieldValue("emailgroups_id", null)
                                                            }
                                                        }} />} label="Enviar E-Mail" />
                                                </FormGroup>
                                            </Grid>
                                            {sendEmail &&
                                                <Grid item md={4} sm={6} xs={12}>
                                                    <Autocomplete
                                                        disablePortal getOptionLabel={(option) => (option.label ? option.label : "")}
                                                        value={selectedEmailGroup}
                                                        onChange={(event, newValue) => {
                                                            setSelectedEmailGroup(newValue)
                                                            formik.setFieldValue("emailgroups_id", newValue?.value)
                                                        }}
                                                        disabled={isView}
                                                        id="combo-email-groups"
                                                        options={emailGroupsOptions}
                                                        renderInput={(params) => <TextField {...params} label="Grupo de E-Mail" />}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions sx={{ ml: 1, pb: 2, pr: 2 }} >
                                    <Grid container direction="row" spacing={2} justifyContent="space-between"
                                        alignItems="center"  >
                                        <Grid item>
                                            <Button variant="contained" aria-label="Volver a listado" color="secondary" onClick={() => { onCancel() }} disabled={false}>
                                                Volver a Listado
                                            </Button>
                                        </Grid>
                                        {!isView &&
                                            <Grid item>
                                                <Button variant="contained" aria-label={getAction()} type={"submit"} disabled={formik.isSubmitting}>
                                                    {getAction()}
                                                </Button>
                                            </Grid>
                                        }
                                    </Grid>
                                </CardActions>
                            </Card>

                        </form>
                    )}
                </Formik>
            }
        </>
    )
}

export default FileInputForm;