import React, { useState, useEffect } from 'react';
import {
    Grid, Button, Alert, Card, CardActions, CardContent, CardHeader, FormGroup, FormControlLabel, Checkbox,
    Autocomplete, TextField, CircularProgress, Box
} from "@mui/material";
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInputMUI from 'components/TextInputMUI';
import axios from 'helpers/CustomAxios';


import PasswordInputMUI from "components/PasswordInputMUI";
function UserForm({ onCancel, onSuccess = null, selectedId, isView = false, isEdit = false, isCreate = false }) {
    const [active, setActive] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null);
    const [initialData, setInitialData] = useState(null);
    const [levelOptions, setLevelOptions] = useState([]);
    const [selectedLevels, setSelectedLevels] = useState([]);

    const [fullyLoaded, setFullyLoaded] = useState(false)
    useEffect(() => {
        let levels;
        axios.get("utils/", {params:{table:"levels", column:"name"}})
            .then(function (response) {
                levels = response.data
                setLevelOptions(levels) 
            })
            .catch(function (error) {
                console.log(error);
                setErrorMsg("Ha ocurrido un error");
            }).then(() => {
                if (selectedId) {
                    axios.get("users/" + selectedId, {})
                        .then(function (response) {
                            let levelIds = response.data.level_user.map((lvl) => lvl.levels_id)
                            setSelectedLevels(levels.filter((lvl) => {
                                return levelIds.includes(lvl.value)
                            }))
                            setInitialData(response.data)
                            setActive(response.data.is_active)
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                        })
                        .then(() => {
                            setFullyLoaded(true)
                        })
                } else {
                    setFullyLoaded(true)
                }
            })


    }, [])

    const getAction = () => {
        if (isCreate) {
            return "Crear"
        }
        else if (isEdit) {
            return "Editar"
        }
        else if (isView) {
            return "Ver"
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                username: initialData ? initialData.username : '',
                full_name: initialData ? initialData.full_name : '',
                password: '',
                confirmPassword: '',
                email: initialData ? initialData.email : '',
                is_active: initialData ? initialData.is_active : false,
                isCreate: isCreate
            }}
            validationSchema={Yup.object({
                username: Yup.string().typeError("Usuario inválido").required("Campo requerido"),
                password:
                    Yup.string().when('isCreate', {
                        is: true, // alternatively: (val) => val == true
                        then: Yup.string().typeError("Contraseña inválida").required("Campo requerido"),
                        otherwise: Yup.string(),
                    }),

                confirmPassword:
                    Yup.string().when('isCreate', {
                        is: true, // alternatively: (val) => val == true
                        then: Yup.string().oneOf([Yup.ref('password'), null], "Las contraseñas no coinciden").required('Campo requerido'),
                        otherwise: Yup.string(),
                    }),
                full_name: Yup.string().typeError("Nombre inválido").required("Campo requerido"),
                email: Yup.string().typeError("E-Mail inválido").required("Campo requerido")
                    .email("E-Mail inválido"),
            })}
            onSubmit={(values, { setSubmitting }) => {

                let level_user = selectedLevels.map((lvl) => { return { levels_id: lvl.value } });
                values.level_user = level_user;
                const myRequest = {
                    level_user: level_user,
                    username: values.username,
                    full_name: values.full_name,
                    email: values.email,
                    is_active: values.is_active
                }

                if (isCreate) {

                    myRequest.password = values.password;
                    axios.post("users/", myRequest)
                        .then(function (response) {
                            onSuccess(values.username + " se ha creado correctamente.")
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                            setSubmitting(false);
                        })
                } else if (isEdit) {
                    axios.delete("levelusers/", { data: { delete: [...initialData.level_user] } })
                        .then(function (response) {
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                            setSubmitting(false);
                        }).then(() => {
                            myRequest.id = selectedId;
                            axios.put("users/" + selectedId, myRequest)
                                .then(function (response) {
                                    onSuccess(values.username + " se ha editado correctamente.")
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    setErrorMsg("Ha ocurrido un error");
                                    setSubmitting(false);
                                })
                        })
                }
            }}
        >
            {formik => (

                <form onSubmit={formik.handleSubmit}>

                    <Card>
                        {!fullyLoaded ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, height: "400px", alignItems: "center", }}>
                                <CircularProgress />
                            </Box> :
                            <>
                                <CardHeader title={getAction() + " Usuario"} />
                                <CardContent>
                                    <Grid container spacing={2} alignContent="center">
                                        {errorMsg &&
                                            <Grid item xs={12}>
                                                <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                                            </Grid>
                                        }
                                        <Grid item container md={12} direction="row" spacing={2}>
                                            <Grid item md={6} sm={6} xs={12}>
                                                <TextInputMUI name="username" label={"Usuario"} required={true} isView={isView} />
                                            </Grid>
                                            <Grid item md={3} sm={3} xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel disabled={isView} control={<Checkbox checked={active} onChange={(event) => {
                                                        formik.setFieldValue("is_active", event.target.checked)
                                                        setActive(event.target.checked)
                                                    }} />} label="Activo" />
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container md={12} direction="row" spacing={2}>
                                            <Grid item md={6} sm={6} xs={12}>
                                                <TextInputMUI name="full_name" label={"Nombre Completo"} required={true} isView={isView} />
                                            </Grid>
                                            <Grid item md={6} sm={6} xs={12}>
                                                <TextInputMUI name="email" label={"E-Mail"} required={true} isView={isView} />
                                            </Grid>
                                        </Grid>
                                        {isCreate &&
                                            <Grid item container md={12} direction="row" spacing={2}>
                                                <Grid item md={6} sm={6} xs={12}>
                                                    <PasswordInputMUI name="password" label={"Contraseña"} required={true} />
                                                </Grid>
                                                <Grid item md={6} sm={6} xs={12}>
                                                    <PasswordInputMUI name="confirmPassword" label={"Confirmar Contraseña"} required={true} />
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid item container md={12} direction="row" spacing={2}>

                                            <Grid item md={6} sm={6} xs={12}>
                                                <Autocomplete
                                                    disablePortal
                                                    multiple
                                                    value={selectedLevels}
                                                    onChange={(event, newValue) => {
                                                        setSelectedLevels(newValue)
                                                    }}
                                                     id="combo-levels"
                                                    options={levelOptions}
                                                    disabled={isView}
                                                    renderInput={(params) => <TextField {...params} disabled={isView} label="Niveles" />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions sx={{ ml: 1, pb: 2, pr: 2 }} >
                                    <Grid container direction="row" spacing={2} justifyContent="space-between"
                                        alignItems="center"  >

                                        {isView ?
                                            <Grid item>
                                                <Button variant="contained" aria-label="Volver a listado" color="primary" onClick={() => { onCancel() }}>
                                                    Volver
                                                </Button>
                                            </Grid>
                                            :
                                            <>
                                                <Grid item>
                                                    <Button variant="contained" aria-label="Cancelar" color="secondary" onClick={() => { onCancel() }} disabled={formik.isSubmitting}>
                                                        Cancelar
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="contained" aria-label={getAction()} type={"submit"} disabled={formik.isSubmitting}>
                                                        {getAction()}
                                                    </Button>
                                                </Grid>
                                            </>

                                        }
                                    </Grid>
                                </CardActions>
                            </>}
                    </Card>

                </form>
            )}
        </Formik>
    )
}

export default UserForm;