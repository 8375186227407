import React, { useState, useEffect } from 'react';
import {
    Grid, Button, Alert, Card, CardActions, CardContent, CardHeader,
    FormGroup, FormControlLabel, Checkbox, Autocomplete, TextField, CircularProgress, Box
} from "@mui/material";
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInputMUI from 'components/TextInputMUI';
import axios from 'helpers/CustomAxios';
import TransferList from 'components/TransferList';

function EmailGroupsForm({ onSuccess = null, selectedId, isView = false, isEdit = false,
    isCreate = false, onCancel = null, accountId = null }) {

    const [active, setActive] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [initialData, setInitialData] = useState(null);
    const [fullyLoaded, setFullyLoaded] = useState(false);

    const [emailOpts, setEmailOpts] = useState([]);
    const [chosenEmails, setChosenEmails] = useState([]);

    useEffect(() => {
        let accountEmails = []
        Promise.all([
            axios.get("accounts/" + accountId, {})
                .then(function (response) {
                    let formattedEmails = []
                    response.data.account_emails.forEach(element => {
                        formattedEmails.push({ label: element.email_address, value: element.id })
                    });
                    accountEmails = formattedEmails
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }),
        ])
            .then(function () {
                if (selectedId) {
                    axios.get("emailgroups/" + selectedId, {})
                        .then(function (response) {
                            if (response.data.accountemailsgroupsemails.length > 0) {
                                let chosen = response.data.accountemailsgroupsemails.map(i => {
                                    const found = accountEmails.find(element => element.value === i.account_emails_id);
                                    return found
                                })
                                const newOpts = accountEmails.filter(n => !chosen.includes(n))
                                setEmailOpts(newOpts)
                                setChosenEmails(chosen)
                            } else {
                                setEmailOpts(accountEmails)
                            }

                            setActive(response.data.is_active)
                            setInitialData(response.data)
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                        }).finally(() => {
                            setFullyLoaded(true)
                        })
                } else {
                    setEmailOpts(accountEmails)
                    setFullyLoaded(true)
                }

            })

    }, [])


    const getAction = () => {
        if (isCreate) {
            return "Crear"
        }
        else if (isEdit) {
            return "Editar"
        }
        else if (isView) {
            return "Ver"
        }
    }

    return (
        <>
            {!fullyLoaded ?
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, height: "400px", alignItems: "center", }}>
                    <CircularProgress />
                </Box>
                :
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        account_id: accountId,
                        fileprocesstype_id: 1,
                        fileproctype_default: true,
                        name: initialData ? initialData.name : '',
                        is_active: initialData ? initialData.is_active : false

                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().typeError("Campo inválido").required("Campo requerido"),
                        is_active: Yup.boolean().typeError("Campo inválido"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);

                        if (isCreate) {
                            values.accountemailsgroupsemails = chosenEmails.map(i => {
                                return { account_emails_id: i.value }
                            })
                            axios.post("emailgroups/", values)
                                .then(function (response) {
                                    onSuccess(values.name + " se ha creado correctamente.")
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    setErrorMsg("Ha ocurrido un error");
                                    setSubmitting(false);
                                })
                        } else if (isEdit) {
                            values.accountemailsgroupsemails = chosenEmails.map(i => {
                                return { email_group_id: selectedId, account_emails_id: i.value }
                            })
                            axios.put("emailgroups/" + selectedId, values)
                                .then(function (response) {
                                    onSuccess(values.name + " se ha editado correctamente.")
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    setErrorMsg("Ha ocurrido un error");
                                    setSubmitting(false);
                                })
                        }
                    }}
                >
                    {formik => (

                        <form onSubmit={formik.handleSubmit}>

                            <Card >
                                <CardHeader title={getAction() + " Grupo de E-Mail"} />
                                <CardContent>
                                    <Grid container spacing={2} alignContent="center">
                                        {errorMsg &&
                                            <Grid item xs={12}>
                                                <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                                            </Grid>
                                        }
                                        <Grid item container md={12} direction="row" spacing={2}>
                                            <Grid item sm={6} xs={12}>
                                                <TextInputMUI name="name" label={"Nombre"} required={true} isView={isView} />
                                            </Grid>
                                            <Grid item sm={6} xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel disabled={isView} control={<Checkbox checked={active}
                                                        onChange={(event) => {
                                                            formik.setFieldValue("is_active", event.target.checked)
                                                            setActive(event.target.checked)
                                                        }} />} label="Activo" />
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container md={12} direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <TransferList isView={isView} left={emailOpts} setLeft={setEmailOpts} right={chosenEmails} setRight={setChosenEmails} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions sx={{ ml: 1, pb: 2, pr: 2 }} >
                                    <Grid container direction="row" spacing={2} justifyContent="space-between"
                                        alignItems="center"  >
                                        <Grid item>
                                            <Button variant="contained" aria-label="Volver a listado" color="secondary" onClick={() => { onCancel() }} disabled={false}>
                                                Volver a Listado
                                            </Button>
                                        </Grid>
                                        {!isView &&
                                            <Grid item>
                                                <Button variant="contained" aria-label={getAction()} type={"submit"} disabled={formik.isSubmitting}>
                                                    {getAction()}
                                                </Button>
                                            </Grid>
                                        }
                                    </Grid>
                                </CardActions>
                            </Card>

                        </form>
                    )}
                </Formik>
            }
        </>
    )
}

export default EmailGroupsForm;