import React, { useState, useEffect } from 'react';
import {
    Grid, Button, Alert, Card, CardActions,
    CardContent, CardHeader, Autocomplete, TextField,
    Box, CircularProgress
} from "@mui/material";
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInputMUI from 'components/TextInputMUI';
import axios from 'helpers/CustomAxios';


function ParameterForm({ onCancel, onSuccess = null, selectedId, isAdmin = false, isView = false, isEdit = false, isCreate = false }) {

    const [errorMsg, setErrorMsg] = useState(null);
    const [initialData, setInitialData] = useState(null);
    const [moduleOptions, setModuleOptions] = useState([]);
    const [selectedModule, setSelectedModule] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [selectedType, setSelectedType] = useState([]);
    const [fullyLoaded, setFullyLoaded] = useState(false)
    useEffect(() => {
        let modulesFormatted;
        let typesFormatted;
        Promise.all([
            axios.get("utils/", { params: { table: "parametersmodules", column: "name" } })
                .then(function (response) {
                    modulesFormatted = response.data;
                    setModuleOptions(modulesFormatted)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                }),
            axios.get("utils/", { params: { table: "parameterstypes", column: "name" } })
                .then(function (response) {
                    typesFormatted = response.data;
                    setTypeOptions(typesFormatted)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg("Ha ocurrido un error");
                })
        ]).then(() => {
            if (selectedId) {
                axios.get("parameters/" + selectedId, {})
                    .then(function (response) {
                        setSelectedModule(modulesFormatted.find(m => m.value === response.data.parameter_module_id))
                        setSelectedType(typesFormatted.find(t => t.value === response.data.parameter_type_id))

                        setInitialData(response.data)
                    })
                    .catch(function (error) {
                        console.log(error);
                        setErrorMsg("Ha ocurrido un error");
                    })
                    .then(() => {
                        setFullyLoaded(true)
                    })
            } else {
                setFullyLoaded(true)
            }
        })

    }, [])



    const getAction = () => {
        if (isCreate) {
            return "Crear"
        }
        else if (isEdit) {
            return "Editar"
        }
        else if (isView) {
            return "Ver"
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                name: initialData ? initialData.name : '',
                value: initialData ? initialData.value : '',
                parameter_module_id: initialData ? initialData.parameter_module_id : 0,
                parameter_type_id: initialData ? initialData.parameter_type_id : 0,
            }}
            validationSchema={Yup.object({
                name: Yup.string().typeError("Nombre inválido").required("Campo requerido"),
                value: Yup.string().typeError("Formato de fecha inválido").required("Campo requerido"),
                parameter_module_id: Yup.number().typeError("Seleccione un modulo").integer("Seleccione un modulo").required("Campo requerido"),
                parameter_type_id: Yup.number().typeError("Seleccione un tipo").integer("Seleccione un tipo").required("Campo requerido"),
            })}
            onSubmit={(values, { setSubmitting }) => {
                values.last_update = new Date();
                if (isCreate) {
                    axios.post("parameters/", values)
                        .then(function (response) {
                            onSuccess(values.name + " se ha creado correctamente.")
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                            setSubmitting(false);
                        })
                } else if (isEdit) {
                    axios.put("parameters/" + selectedId, values)
                        .then(function (response) {
                            onSuccess(values.name + " se ha editado correctamente.")
                        })
                        .catch(function (error) {
                            console.log(error);
                            setErrorMsg("Ha ocurrido un error");
                            setSubmitting(false);
                        })
                }


            }}
        >
            {formik => (

                <form onSubmit={formik.handleSubmit}>

                    <Card>
                        {!fullyLoaded ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, height: "400px", alignItems: "center", }}>
                                <CircularProgress />
                            </Box> :
                            <>
                                <CardHeader title={getAction() + " Parámetro"} />
                                <CardContent>

                                    <Grid container spacing={2} alignContent="center">
                                        {errorMsg &&
                                            <Grid item xs={12}>
                                                <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                                            </Grid>
                                        }
                                        <Grid item container md={12} direction="row" spacing={2}>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <TextInputMUI name="name" label={"Nombre"} required={true} isView={isView || !isAdmin} />
                                            </Grid>

                                            <Grid item md={4} sm={6} xs={12}>
                                                <TextInputMUI name="value" label={"Valor"} required={true} isView={isView} />
                                            </Grid>
                                        </Grid>
                                        <Grid item container md={12} direction="row" spacing={2}>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <Autocomplete
                                                    disablePortal
                                                    value={selectedModule}
                                                    onChange={(event, newValue) => {
                                                        setSelectedModule(newValue)
                                                        formik.setFieldValue("parameter_module_id", newValue.value)
                                                    }}
                                                    disabled={isView}
                                                    id="combo-modulos"
                                                    options={moduleOptions}
                                                    renderInput={(params) => <TextField {...params} label="Módulo" />}
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <Autocomplete
                                                    disablePortal
                                                    value={selectedType}
                                                    onChange={(event, newValue) => {
                                                        setSelectedType(newValue)
                                                        formik.setFieldValue("parameter_type_id", newValue.value)
                                                    }}
                                                    disabled={isView}
                                                    id="combo-tipos"
                                                    options={typeOptions}
                                                    renderInput={(params) => <TextField {...params} label="Tipo" />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </CardContent>
                                <CardActions sx={{ ml: 1, pb: 2, pr: 2 }} >
                                    <Grid container direction="row" spacing={2} justifyContent="space-between"
                                        alignItems="center"  >

                                        {isView ?
                                            <Grid item>
                                                <Button variant="contained" aria-label="Volver a listado" color="primary" onClick={() => { onCancel() }}>
                                                    Volver
                                                </Button>
                                            </Grid>
                                            :
                                            <>
                                                <Grid item>
                                                    <Button variant="contained" aria-label="Cancelar" color="secondary" onClick={() => { onCancel() }} disabled={formik.isSubmitting}>
                                                        Cancelar
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="contained" aria-label={getAction()} type={"submit"} disabled={formik.isSubmitting}>
                                                        {getAction()}
                                                    </Button>
                                                </Grid>
                                            </>

                                        }
                                    </Grid>
                                </CardActions>
                            </>
                        }
                    </Card>

                </form>
            )}
        </Formik>
    )
}

export default ParameterForm;