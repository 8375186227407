import React, { useState, useEffect, useRef } from 'react';
import {
    Grid, Typography, Box, Alert, FormControl, MenuItem, InputLabel, Select,
    Card, CardContent, CardActions, Button
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { GridLocalization } from 'helpers/general';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInputMUI from 'components/TextInputMUI';
import axios from 'helpers/AxiosDashboard';
import { format, parse, subHours } from 'date-fns'
import CustomPagination from 'components/CustomPagination';
import DatePickerFormik from 'components/DatePickerFormik';
import { parseDateString } from 'helpers/general';
import { CSVLink } from "react-csv";
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const FilterPanel = ({ setFilter }) => {
    const [fileStatus, setFileStatus] = useState(0);
    const [timeCreated, setTimeCreated] = useState(null)

    const onClear = (formik) => {
        formik.handleReset()
        setFileStatus(0)
        formik.handleSubmit()

    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                fiid: '',
                fileStatus: 0,
                time_created: ''
            }}
            validationSchema={Yup.object({
                fiid: Yup.string().typeError("Nombre inválido"),
                time_created: Yup.date().nullable().typeError("Ingrese una fecha").transform(parseDateString)
            })}
            onSubmit={(values, { setSubmitting }) => {
                setFilter(values)
                setSubmitting(false)
            }}
        >
            {formik => (

                <form onSubmit={formik.handleSubmit}>

                    <Card>
                        <CardContent sx={{ pt: 2 }}>
                            <Grid container spacing={2} alignContent="center">
                                <Grid item md={3} sm={4} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Estado</InputLabel>
                                        <Select
                                            value={fileStatus}
                                            label="Estado"
                                            onChange={(e) => {
                                                setFileStatus(parseInt(e.target.value))
                                                formik.setFieldValue("fileStatus", parseInt(e.target.value))

                                            }}
                                        >
                                            <MenuItem value={0}>Todos</MenuItem>
                                            <MenuItem value={1}>Escaneado</MenuItem>
                                            <MenuItem value={2}>Procesando</MenuItem>
                                            <MenuItem value={3}>Aceptado</MenuItem>
                                            <MenuItem value={4}>Rechazado</MenuItem>
                                            <MenuItem value={5}>Retenido</MenuItem>
                                            <MenuItem value={6}>A Futuro</MenuItem>
                                            <MenuItem value={7}>Enviado</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={3} sm={4} xs={12}>
                                    <TextInputMUI type="text" name="fiid" label={"Empresa"} />
                                </Grid>
                                <Grid item md={3} sm={4} xs={12}>
                                    <DatePickerFormik
                                        inputValue={timeCreated}
                                        onChangeInputValue={(newValue) => { setTimeCreated(newValue) }}
                                        datepickerprops={{ maxDate: new Date() }}
                                        label="Fecha de Creación"
                                        name="time_created"
                                        dateformat="yyyy-MM-dd"
                                        required={false}
                                    />
                                </Grid>
                                <Grid container direction="row" spacing={2}  alignItems="center" justifyContent={"flex-end"} item md={3} sm={4} xs={12}>
                                <Grid item>
                                    <Button variant="contained" aria-label={"Filtrar"} type={"submit"} disabled={formik.isSubmitting}>
                                        Filtrar
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" aria-label="Limpiar filtro" color="secondary" onClick={() => { onClear(formik) }} disabled={formik.isSubmitting}>
                                        Limpiar
                                    </Button>
                                </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>                       
                    </Card>

                </form>
            )}
        </Formik>
    )
}
function FileGrid() {
    const [data, setData] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [page, setPage] = useState(0);
    const [loadingData, setLoadingData] = useState(true);

    const [errorMsg, setErrorMsg] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filter, setFilter] = useState(
        {
            fileStatus: 0,
            fiid: null
        }
    );

    const [csvData, setCsvData] = useState([])
    const [downloading, setDownloading] = useState(false)
    const csvLink = useRef(null);
    useEffect(() => {
        loadData();
    }, [pageSize, page, filter])

    useEffect(() => {
        if (csvData.length > 0 && csvLink) {
            csvLink.current.link.click()
            setDownloading(false)
            setCsvData([])
        }
    }, [csvData])

  /*   const mockData = [
        {
            id: 1, time_created: "2022-11-09 00:30", fiid: "TEST",
            original_filename: "test file", current_process_id: 2, current_status_id: 1,
            motive: "", comments: ""
        }] */

    const columns = [
        {
            field: 'time_created', headerName: 'Creación', flex: 1, sortable: false,
            renderCell: (params) => {
                return format(subHours(parse(params.row.time_created.substring(0, 10) + " " + params.row.time_created.substring(11, 16), "yyyy-MM-dd HH:mm", new Date()), 3), "yyyy-MM-dd HH:mm")
            }
        },
        {
            field: 'fiid', headerName: 'Empresa', flex: 1, sortable: false,
            renderCell: (params) => {
                return params.row.fiid.toUpperCase()
            }
        },
        { field: 'original_filename', headerName: 'Original', flex: 2, sortable: false },
        {
            field: 'current_process_id', headerName: 'Proceso', flex: 1, sortable: false,
            renderCell: (params) => {
                switch (params.row.current_process_id) {
                    case 1:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    Scanner
                                </Typography>
                            </Box>
                        )
                    case 2:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    Meta Validator
                                </Typography>
                            </Box>
                        )
                    case 3:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    File Processor
                                </Typography>
                            </Box>
                        )
                    case 4:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    Decryptor
                                </Typography>
                            </Box>
                        )
                    case 5:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    Unzip
                                </Typography>
                            </Box>
                        )
                    case 6:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    EBCDIC 2 ASCII
                                </Typography>
                            </Box>
                        )
                    case 7:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    Ftp Sender
                                </Typography>
                            </Box>
                        )
                    case 8:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    File Notificator
                                </Typography>
                            </Box>
                        )
                    case 9:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    Future Processing
                                </Typography>
                            </Box>
                        )
                    default:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    Cod: {params.row.current_process_id.toString()}
                                </Typography>
                            </Box>
                        )
                }

            }
        },
        {
            field: 'current_status_id', headerName: 'Estado', flex: 1, sortable: false,
            renderCell: (params) => {
                switch (params.row.current_status_id) {
                    case 1:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    Escaneado
                                </Typography>
                            </Box>
                        )
                    case 2:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    Procesando
                                </Typography>
                            </Box>
                        )
                    case 3:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    Aceptado
                                </Typography>
                            </Box>
                        )
                    case 4:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    Rechazado
                                </Typography>
                            </Box>
                        )
                    case 5:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    Retenido
                                </Typography>
                            </Box>
                        )
                    case 6:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    A Futuro
                                </Typography>
                            </Box>
                        )
                    case 7:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    Enviado
                                </Typography>
                            </Box>
                        )
                    default:
                        return (
                            <Box component="div" justifyContent="center" width="100%" >
                                <Typography color={"text.primary"}                                >
                                    Cod: {params.row.current_status_id.toString()}
                                </Typography>
                            </Box>
                        )
                }

            }
        },
        {
            field: 'motive', headerName: 'Motivo de Rechazo', flex: 2, sortable: false,
            renderCell: (params) => {
                return params.row.motive?.motive
            }
        },
        {
            field: 'comments', headerName: 'Comentarios', flex: 2, sortable: false,

        },
    ];

    const downloadCSV = () => {
        setDownloading(true)
        let params = {
            filestatus: filter.fileStatus === 0 ? null : filter.fileStatus,
            fiid: filter.fiid === "" ? null : filter.fiid,
        }
        if (filter.time_created) {
            params.time_created = filter.time_created
        }
        axios.get("dashboards/all", {
            params: params
        }).then(function (response) {
            setCsvData(response.data)
        })
            .catch(function (error) {
                console.log(error);
                setErrorMsg("Ha ocurrido un error");
                setCsvData([])
            })
    }

    const loadData = () => {
        setLoadingData(true);
        let params = {
            page: page + 1, size: pageSize,
            filestatus: filter.fileStatus === 0 ? null : filter.fileStatus,
            fiid: filter.fiid === "" ? null : filter.fiid,
        }
        if (filter.time_created) {
            params.time_created = filter.time_created
        }
        axios.get("dashboards/", {
            params: params
        })
            .then(function (response) {
                setData(response.data.items);
                setTotalRecords(response.data.total)
            })
            .catch(function (error) {
                console.log(error);
                setErrorMsg("Ha ocurrido un error");
            })
            .then(function () {
                setLoadingData(false)
            })
    }


    return (

        <Grid container spacing={2}>
            <Grid item xs={12} container spacing={2} direction="row" justifyContent={{ xs: "center", sm: "space-between" }} alignItems="center">
                <Grid item md={9} sm={8} xs={12}>
                    <Typography variant="h4">Archivos Ingresados {format(new Date(), "dd-MM-yyyy")}</Typography>
                </Grid>
                <Grid item md={3} sm={4} xs={12}>
                    <Box display="flex" justifyContent={{ xs: "center", sm: "flex-end" }}>
                        <LoadingButton loading={downloading} aria-label="Descargar CSV" variant="contained" size="large"
                            onClick={() => { downloadCSV() }} disabled={totalRecords === 0} startIcon={<FileDownloadIcon />}>
                            CSV
                        </LoadingButton>
                        <CSVLink
                            data={csvData}
                            separator={";"}
                            filename={"Archivos Ingresados " + format(new Date(), "dd-MM-yyyy") + ".csv"}
                            className="hidden"
                            ref={csvLink}
                            target="_blank"
                        />
                    </Box>
                </Grid>
                {errorMsg &&
                    <Grid item xs={12}>
                        <Alert severity="error" onClose={() => { setErrorMsg(null) }} sx={{ width: '100%' }}>{errorMsg}</Alert>
                    </Grid>
                }
            </Grid>
            <Grid item xs={12}>
                <FilterPanel setFilter={setFilter} />
            </Grid>
            <Grid item xs={12}>
                <div style={{ height: 450, width: '100%' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                sx={{
                                    '& .MuiDataGrid-renderingZone': {
                                        maxHeight: 'none !important',
                                    },
                                    '& .MuiDataGrid-cell': {
                                        lineHeight: 'unset !important',
                                        maxHeight: 'none !important',
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                    },
                                    '& .MuiDataGrid-row': {
                                        maxHeight: 'none !important'
                                    },
                                    /*  '.MuiDataGrid-virtualScrollerContent': {
                                         height: '100% !important',
                                         overflow: 'scroll'
                                     } */
                                }}
                                disableVirtualization
                                localeText={GridLocalization}
                                disableColumnFilter
                                density="compact"
                                pagination
                                paginationMode="server"
                                components={{
                                    Pagination: CustomPagination,
                                }}
                                rowCount={totalRecords}
                                loading={loadingData}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                page={page}
                                onPageChange={(newPage) => setPage(newPage)}
                                rows={data}
                                columns={columns} />
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>

    )
}

export default FileGrid;