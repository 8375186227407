import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import SecurityIcon from '@mui/icons-material/Security';
import AppsIcon from '@mui/icons-material/Apps';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemButton from '@mui/material/ListItemButton';
import DashboardIcon from '@mui/icons-material/Dashboard';

import ServerAdministration from './configuration/ServerAdministration';
import SpecialNameAdministration from './configuration/SpecialNameAdministration';
import ValidationGroupAdministration from './configuration/ValidationGroupAdministration';
import OutputDesignsAdministration from './configuration/OutputDesignsAdministration';
import HeadingsAdministration from './configuration/HeadingsAdministration';
import FileDataInfoAdministration from './configuration/files/FileDataInfoAdministration';
import FileSequencesAdministration from './configuration/files/FileSequencesAdministration';
import FileScheduleAdministration from './configuration/files/FileScheduleAdministration';
import FileProcessTypeAdministration from './configuration/files/FileProcessTypeAdministration';
import FileMaskAdministration from './configuration/files/FileMaskAdministration';
import FileEncodingAdministration from './configuration/files/FileEncodingAdministration';
import DateFormatAdministration from './configuration/DateFormatAdministration';
import DesignAdministration from './configuration/DesignAdministration';
import HolidayAdministration from './configuration/HolidayAdministration';
import LevelAdministration from './security/LevelAdministration';
import UserAdministration from './security/UserAdministration';

import AccountAdministration from './account/AccountAdministration';
import ParameterTypeAdministration from './configuration/parameters/ParameterTypeAdministration';
import ParameterModuleAdministration from './configuration/parameters/ParameterModuleAdministration';
import ParameterAdministration from './configuration/parameters/ParameterAdministration';
import Login from "containers/Login";
import useToken from 'helpers/useToken';
import FileMonitor from './dashboard/FileMonitor';
import FileGrid from './dashboard/FileGrid';
import AdminModules from './dashboard/AdminModules';
import { adminPermissions, operacionesPermissions, viewerPermissions } from 'helpers/menuPermissions';
import ChangePassword from './user/ChangePassword';

import { parseJwt } from 'helpers/general'
const drawerWidth = 240;


function Main() {

    const { token, setToken } = useToken();

    //Config drawer options

    const [mobileOpen, setMobileOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState("fileMonitor")
    const [openConfigurationMenu, setOpenConfigurationMenu] = useState(false);
    const [openFileMenu, setOpenFileMenu] = useState(false);
    //const [openEmailMenu, setOpenEmailMenu] = useState(false);
    const [openSecurityMenu, setOpenSecurityMenu] = useState(false);
    const [openDashboardMenu, setOpenDashboardMenu] = useState(true);
    const [openParameterMenu, setOpenParameterMenu] = useState(false);
    const [mainBackground, setMainBackground] = useState("background.paper")
    //anchor user account menu
    const [anchorEl, setAnchorEl] = useState(null);

    const [appBarLabel,setAppBarLabel] = useState(null)

    const [userPermissions, setUserPermissions] = useState([])

    const hasAccess = (permissions) => {
        let access = false
        if (!Array.isArray(permissions)) {
            return userPermissions.includes(permissions)
        } else {

            access = permissions.some(p => userPermissions.includes(p));
        }
        return access
    }
    useEffect(() => {
        if (selectedItem !== "fileMonitor") {
            setMainBackground("background.paper")
        }

    }, [selectedItem])

    useEffect(() => {
        if (token) {
            setSelectedItem("fileMonitor")
            let levels = parseJwt(token).level_user
            let permissions = []
            //viewer
            if (levels.includes(3)) {
                permissions = [...permissions, ...viewerPermissions]
            }
            //configurator
            if (levels.includes(2)) {
                permissions = [...permissions, ...operacionesPermissions]
            }
            //admin
            if (levels.includes(1)) {
                permissions = [...permissions, ...adminPermissions]
            }
            //Remove duplicates
            permissions = permissions.filter((i, p) => permissions.indexOf(i) === p);
            setUserPermissions(permissions)
        } else {
            setUserPermissions([])
        }
    }, [token])
    /* Cheating the useState hook. If you click on the selected menu
    item, by default it doesn't rerender because the state is the "same item".
    If there's no timeOut setting the state to a string or to null takes so 
    little time it doesn't rerender either.
    This is useful to get out of deeply nested forms like the account ones.
    */
    const handleChangeItem = (value) => {        
        if (selectedItem === value) {
            setSelectedItem(null)
            setTimeout(() => {
                setSelectedItem(value)
            }, 10)
        } else {
            setSelectedItem(value)
        }
    }

    useEffect(()=>{
        if(selectedItem !== 'accounts'){
            setAppBarLabel(null)
        }
    },[selectedItem])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Box sx={{ pl: 2, pr: 2, display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <img src="/prisma-logo-color.svg" alt="logo" />
            </Box>

            <Divider />
            <List>
                {hasAccess(["fileMonitor", "fileGrid", "modules"]) &&
                    <ListItemButton
                        onClick={() => {
                            setOpenDashboardMenu(!openDashboardMenu);
                        }}>
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Dashboard"} />
                        {openDashboardMenu ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                }
                <Collapse in={openDashboardMenu} timeout="auto" >
                    <List component="div" disablePadding dense>
                        {hasAccess("fileMonitor") &&
                            <ListItemButton sx={{ pl: 3 }}
                                selected={selectedItem === "fileMonitor"}
                                onClick={() => { handleChangeItem("fileMonitor") }}>
                                <ListItemText primary="Monitor" />
                            </ListItemButton>
                        }
                        {hasAccess("fileGrid") &&
                            <ListItemButton sx={{ pl: 3 }}
                                selected={selectedItem === "fileGrid"}
                                onClick={() => { handleChangeItem("fileGrid") }}>
                                <ListItemText primary="Archivos" />
                            </ListItemButton>
                        }
                        {hasAccess("modules") &&
                            <ListItemButton sx={{ pl: 3 }}
                                selected={selectedItem === "modules"}
                                onClick={() => { handleChangeItem("modules") }}>
                                <ListItemText primary="Módulos" />
                            </ListItemButton>
                        }
                    </List>
                </Collapse>
            </List>
            <Divider />
            <List>
                <ListItemButton
                    selected={selectedItem === "accounts"}
                    onClick={() => { handleChangeItem("accounts") }}>
                    <ListItemIcon>
                        <AppsIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Cuentas"} />
                </ListItemButton>
            </List>
            <Divider />
            <List>
                {hasAccess(["designs",
                    "holidays",
                    "dateformats",
                    "validationgroupadministration",
                    "headings",
                    "specialnames",
                    "outputdesign",
                    "servers", "emailgroups",
                    "emailtemplates",
                    "emailgroupstemplates", "filedatainfo",
                    "fileencodings",
                    "filemasks",
                    "fileprocesstype",
                    "fileschedules",
                    "filesequences", "parameters",
                    "parameterTypes",
                    "parameterModules"]) &&
                    <ListItemButton
                        onClick={() => {
                            let newOpen = !openConfigurationMenu;
                            setOpenConfigurationMenu(newOpen);
                            if (!newOpen) {
                                setOpenFileMenu(false)
                            }
                        }}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Configuración"} />
                        {openConfigurationMenu ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                }
                {/* Menu de Configuracion */}
                <Collapse in={openConfigurationMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        {hasAccess("designs") &&
                            <ListItemButton sx={{ pl: 3 }}
                                selected={selectedItem === "designs"}
                                onClick={() => { handleChangeItem("designs") }}>
                                <ListItemText primary="Diseños" />
                            </ListItemButton>
                        }                        
                        {hasAccess("holidays") &&
                            <ListItemButton sx={{ pl: 3 }}
                                selected={selectedItem === "holidays"}
                                onClick={() => { handleChangeItem("holidays") }}>
                                <ListItemText primary="Feriados" />
                            </ListItemButton>
                        }
                        {/* Menu File */}
                        {hasAccess(["filedatainfo",
                            "fileencodings",
                            "filemasks",
                            "fileprocesstype",
                            "fileschedules",
                            "filesequences",]) &&
                            <ListItemButton sx={{ pl: 3 }}
                                onClick={() => { setOpenFileMenu(!openFileMenu) }}>
                                <ListItemText primary={"Archivos"} />
                                {openFileMenu ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        }
                        <Collapse in={openFileMenu} timeout="auto" >
                            <List component="div" disablePadding dense>
                                {hasAccess("filedatainfo") &&
                                    <ListItemButton sx={{ pl: 6 }}
                                        selected={selectedItem === "filedatainfo"}
                                        onClick={() => { handleChangeItem("filedatainfo") }}>
                                        <ListItemText primary="Data Info" />
                                    </ListItemButton>
                                }
                                {hasAccess("fileencodings") &&
                                    <ListItemButton sx={{ pl: 6 }}
                                        selected={selectedItem === "fileencodings"}
                                        onClick={() => { handleChangeItem("fileencodings") }}>
                                        <ListItemText primary="Encodings" />
                                    </ListItemButton>
                                }
                                {hasAccess("filemasks") &&
                                    <ListItemButton sx={{ pl: 6 }}
                                        selected={selectedItem === "filemasks"}
                                        onClick={() => { handleChangeItem("filemasks") }}>
                                        <ListItemText primary="Masks" />
                                    </ListItemButton>
                                }
                                {hasAccess("fileprocesstype") &&
                                    <ListItemButton sx={{ pl: 6 }}
                                        selected={selectedItem === "fileprocesstype"}
                                        onClick={() => { handleChangeItem("fileprocesstype") }}>
                                        <ListItemText primary="Process Types" />
                                    </ListItemButton>
                                }
                                {hasAccess("fileschedules") &&
                                    <ListItemButton sx={{ pl: 6 }}
                                        selected={selectedItem === "fileschedules"}
                                        onClick={() => { handleChangeItem("fileschedules") }}>
                                        <ListItemText primary="Schedules" />
                                    </ListItemButton>
                                }
                                {hasAccess("filesequences") &&
                                    <ListItemButton sx={{ pl: 6 }}
                                        selected={selectedItem === "filesequences"}
                                        onClick={() => { handleChangeItem("filesequences") }}>
                                        <ListItemText primary="Sequences" />
                                    </ListItemButton>
                                }
                            </List>
                        </Collapse>
                        {/* End Menu File */}
                        {hasAccess("dateformats") &&
                            <ListItemButton sx={{ pl: 3 }}
                                selected={selectedItem === "dateformats"}
                                onClick={() => { handleChangeItem("dateformats") }}>
                                <ListItemText primary="Formatos de Fecha" />
                            </ListItemButton>
                        }
                        {hasAccess("validationgroupadministration") &&
                            <ListItemButton sx={{ pl: 3 }}
                                selected={selectedItem === "validationgroupadministration"}
                                onClick={() => { handleChangeItem("validationgroupadministration") }}>
                                <ListItemText primary="Grupos de Validación" />
                            </ListItemButton>
                        }
                        {hasAccess("headings") &&
                            <ListItemButton sx={{ pl: 3 }}
                                selected={selectedItem === "headings"}
                                onClick={() => { handleChangeItem("headings") }}>
                                <ListItemText primary="Rubros" />
                            </ListItemButton>
                        }
                        {hasAccess("specialnames") &&
                            <ListItemButton sx={{ pl: 3 }}
                                selected={selectedItem === "specialnames"}
                                onClick={() => { handleChangeItem("specialnames") }}>
                                <ListItemText primary="Nombres Especiales" />
                            </ListItemButton>
                        }
                        {hasAccess("outputdesign") &&
                            <ListItemButton sx={{ pl: 3 }}
                                selected={selectedItem === "outputdesign"}
                                onClick={() => { handleChangeItem("outputdesign") }}>
                                <ListItemText primary="Output Designs" />
                            </ListItemButton>
                        }
                        {/* Menu Parameter */}
                        {hasAccess(["parameters",
                            "parameterTypes",
                            "parameterModules"]) &&
                            <ListItemButton sx={{ pl: 3 }}
                                onClick={() => { setOpenParameterMenu(!openParameterMenu) }}>
                                <ListItemText primary={"Parámetros"} />
                                {openParameterMenu ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        }
                        <Collapse in={openParameterMenu} timeout="auto" >
                            <List component="div" disablePadding dense>
                                {hasAccess("parameters") &&
                                    <ListItemButton sx={{ pl: 6 }}
                                        selected={selectedItem === "parameters"}
                                        onClick={() => { handleChangeItem("parameters") }}>
                                        <ListItemText primary="Parámetros" />
                                    </ListItemButton>
                                }
                                {hasAccess("parameterTypes") &&
                                    <ListItemButton sx={{ pl: 6 }}
                                        selected={selectedItem === "parameterTypes"}
                                        onClick={() => { handleChangeItem("parameterTypes") }}>
                                        <ListItemText primary="Tipos" />
                                    </ListItemButton>
                                }
                                {hasAccess("parameterModules") &&
                                    <ListItemButton sx={{ pl: 6 }}
                                        selected={selectedItem === "parameterModules"}
                                        onClick={() => { handleChangeItem("parameterModules") }}>
                                        <ListItemText primary="Módulos" />
                                    </ListItemButton>
                                }
                            </List>
                        </Collapse>
                        {/* End Menu Parameter */}
                        {hasAccess("servers") &&
                            <ListItemButton sx={{ pl: 3 }}
                                selected={selectedItem === "servers"}
                                onClick={() => { handleChangeItem("servers") }}>
                                <ListItemText primary="Servidores" />
                            </ListItemButton>
                        }
                    </List>
                </Collapse>

                {/* End Menu de Configuracion */}
                {hasAccess(["levels",
                    "users"]) &&
                    <React.Fragment>
                        <Divider />
                        <ListItemButton
                            onClick={() => {
                                setOpenSecurityMenu(!openSecurityMenu);
                            }}>
                            <ListItemIcon>
                                <SecurityIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Seguridad"} />
                            {openSecurityMenu ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </React.Fragment>

                }
                <Collapse in={openSecurityMenu} timeout="auto" >
                    <List component="div" disablePadding dense>
                        {hasAccess("levels") &&
                            <ListItemButton sx={{ pl: 3 }}
                                selected={selectedItem === "levels"}
                                onClick={() => { handleChangeItem("levels") }}>
                                <ListItemText primary="Niveles" />
                            </ListItemButton>
                        }
                        {hasAccess("users") &&
                            <ListItemButton sx={{ pl: 3 }}
                                selected={selectedItem === "users"}
                                onClick={() => { handleChangeItem("users") }}>
                                <ListItemText primary="Usuarios" />
                            </ListItemButton>
                        }
                    </List>
                </Collapse>
            </List>

        </div>
    );

    const loggedInContent = (
        <Box sx={{ display: 'flex' }}>
            <AppBar
                position="fixed"
                sx={{
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                    ml: { md: `${drawerWidth}px` },
                }}
            >
                <Toolbar sx={{ 
                    justifyContent: { xs: "space-between", sm: "space-between", md: "space-between" } }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6">{appBarLabel}</Typography>
                    <Box component="div" sx={{ height: 'auto', width: 200, backgroundColor: "white", pr: 2, pl: 2, display: { md: 'none' } }}>
                        <img src="/prisma-logo-color.svg" alt="logo" />
                    </Box>
                    
                    <div>                        
                        <IconButton
                            color="inherit"
                            aria-label="open account menu"
                            edge="start"
                            aria-haspopup="true"
                            onClick={(event) => { setAnchorEl(event.currentTarget) }}
                        >
                            <AccountCircleIcon />
                        </IconButton>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={() => { setAnchorEl(null) }}
                        >
                            <MenuItem onClick={() => {
                                setAnchorEl(null);
                                handleChangeItem("changePassword")
                            }}>Cambiar Contraseña</MenuItem>
                            <MenuItem onClick={() => { setAnchorEl(null); setToken(null) }}>Salir</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1, px: { xs: 1, sm: 3 }, py: 3,
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                    backgroundColor: mainBackground,
                    minHeight: `calc(100vh)`
                }}
            >
                <Toolbar />
                {selectedItem === "fileMonitor" &&
                    <FileMonitor setBackgroundColor={setMainBackground} />
                }
                {selectedItem === "fileGrid" &&
                    <FileGrid />
                }
                {selectedItem === "modules" &&
                    <AdminModules />
                }
                {selectedItem === "accounts" &&
                    <AccountAdministration setAppBarLabel={setAppBarLabel}/>
                }
                {/* CONFIGURACION */}
                {selectedItem === "servers" &&
                    <ServerAdministration />
                }
                {selectedItem === "specialnames" &&
                    <SpecialNameAdministration />}
                {selectedItem === "validationgroupadministration" &&
                    <ValidationGroupAdministration />
                }
                {selectedItem === "filedatainfo" &&
                    <FileDataInfoAdministration />
                }
                {selectedItem === "outputdesign" &&
                    <OutputDesignsAdministration />
                }
                {selectedItem === "headings" &&
                    <HeadingsAdministration />
                }
                {selectedItem === "filesequences" &&
                    <FileSequencesAdministration />
                }
                {selectedItem === "fileschedules" &&
                    <FileScheduleAdministration />
                }
                {selectedItem === "fileprocesstype" &&
                    <FileProcessTypeAdministration />
                }
                {selectedItem === "filemasks" &&
                    <FileMaskAdministration />
                }
                {selectedItem === "fileencodings" &&
                    <FileEncodingAdministration />
                }
                {selectedItem === "dateformats" &&
                    <DateFormatAdministration />
                }
                {selectedItem === "designs" &&
                    <DesignAdministration />
                }
                {selectedItem === "holidays" &&
                    <HolidayAdministration />
                }
                {selectedItem === "parameterTypes" &&
                    <ParameterTypeAdministration />
                }
                {selectedItem === "parameterModules" &&
                    <ParameterModuleAdministration />
                }
                {selectedItem === "parameters" &&
                    <ParameterAdministration />
                }
                {/* SEGURIDAD */}
                {selectedItem === "levels" &&
                    <LevelAdministration />
                }
                {selectedItem === "users" &&
                    <UserAdministration />
                }
                {selectedItem === "changePassword" &&
                    <ChangePassword />
                }
            </Box>
        </Box>
    )

    
    if (!token) {
        return (<Login setToken={setToken} />)
    } else {
        return (
            <div>
                {loggedInContent}
            </div>
        );
    }
}


export default Main;